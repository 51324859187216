import React from 'react';
import AnnotatedImage from './AnnotatedImage';
import HelpPane from './HelpPane';
import { imageHasPolylines } from '../models/image';
import styles from './LabelingContainer.module.css';

export default function LabelingContainer(props) {
  const { image, projectType } = props;
  const hasPolylines = projectType === 'QC' && imageHasPolylines(image);

  return (
    <div className={styles.container}>
      <AnnotatedImage {...props} hasPolylines={hasPolylines} />
      <HelpPane isPolylines={hasPolylines} projectType={projectType} />
    </div>
  );
}
