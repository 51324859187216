import React, { Component } from 'react';
import styles from './GoogleButton.module.css';
import { auth } from '../utils';
import { loginGoogle } from '../actions/authActions';

const ERR_MSG = 'There was an issue logging in with Google. Please try again.';

export default class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.state = { isReady: false, isSubmitting: false };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    auth.initGoogleAuth().then(() => this.setState({ isReady: true }));
  }

  handleClick() {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    const { onError, onLogin } = this.props;

    this.setState({ isSubmitting: true }, () => {
      GoogleAuth.signIn({
        prompt: 'select_account',
        scope: 'profile email',
        ux_mode: 'popup',
      })
        .then(() => {
          const currentUser = GoogleAuth.currentUser.get();
          const authResponse = currentUser.getAuthResponse();

          return loginGoogle(authResponse.id_token);
        })
        .then(() => onLogin())
        .catch((err) => {
          if (err.error && err.error !== 'popup_closed_by_user') {
            onError(ERR_MSG);
          } else {
            onError('Incorrect user.');
          }
          this.setState({ isSubmitting: false });
        });
    });
  }

  render() {
    const { isReady, isSubmitting } = this.state;

    return (
      <button
        className={styles.loginGoogle}
        disabled={!isReady || isSubmitting}
        onClick={this.handleClick}
        type="button"
      >
        Login with Google
      </button>
    );
  }
}
