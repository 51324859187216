import { clamp, createId } from '../utils';

export function createAnnotation({ label, height, width, left, top }) {
  return {
    label,
    height,
    width,
    _id: createId(),
    left: clamp(left - (width / 2), 0, 1),
    top: clamp(top - (height / 2), 0, 1),
  };
}

export function prepareAnnotationsForSave(annotations) {
  return annotations.map((annotation) => {
    if (annotation._id.startsWith('new')) {
      return {
        top: annotation.top,
        left: annotation.left,
        width: annotation.width,
        height: annotation.height,
        label: annotation.label,
      };
    }

    return annotation;
  });
}
