import React from 'react';
import AnnotationsList from './AnnotationsList';
import ImageProperties from './ImageProperties';
import PolylinesList from './PolylinesList';
import styles from './LabelingSidebar.module.css';
import { imageHasPolylines } from '../models/image';

export default function LabelingSidebar({
  activeAnnotationIndex,
  activePolylineIndex,
  groupInfo,
  image,
  labels,
  lastPolyWidth,
  projectType,
  polylines,
  toggleSurvey,
  updateImage,
}) {
  const hasPolylines = imageHasPolylines(image);
  return (
    <div className={styles.sidebar}>
      { !hasPolylines &&
      <ImageProperties
        groupInfo={groupInfo}
        image={image}
        labels={labels}
        toggleSurvey={toggleSurvey}
        updateImage={updateImage}
      />}
      {projectType === 'Labeling' && (
        <AnnotationsList
          activeIndex={activeAnnotationIndex}
          image={image}
          labels={labels}
        />
      )}
      { hasPolylines &&
        <PolylinesList
          activeIndex={activePolylineIndex}
          labels={labels}
          lastPolyWidth={lastPolyWidth}
          polylines={polylines}
        />
      }
    </div>
  );
}
