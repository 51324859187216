import React, { Component } from 'react';
import proj4 from 'proj4';
import { Pano, NavMarkers } from '@avio/react-pano';
import WorldPointOverlay from './WorldPointOverlay';
import styles from './CameraOverlay.module.css';
import { api, auth } from '../utils';

const NO_OP_FN = () => {};

function getWorldDimensions({ top, right, bottom, left }) {
  return {
    height: top - bottom,
    width: right - left,
    bottom,
    left,
  };
}

export default class CameraOverlay extends Component {
  constructor(props) {
    super(props);

    // Create a lat/lng converter so we can query for photos.
      const { driveInfo } = props;
    this.projector = new proj4(driveInfo.coordinateSystem, driveInfo.latLngTargetSystem);
    this.state = {
      dragging: false,
      markerLocalPosition: { x: 0, y: 0 },
      panoLatLng: null,
      photoIndex: null,
      photoInfo: null,
    };

    this.dragStart = this.dragStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.onPanoIndexChanged = this.onPanoIndexChanged.bind(this);
    this.onPanoViewChanged = this.onPanoViewChanged.bind(this);
  }

  _getLatLng(world) {
    const { driveInfo } = this.props;
    const [lng, lat] = this.projector.forward([
      world.x / driveInfo.driveUnitsToMeters,
      world.y / driveInfo.driveUnitsToMeters,
    ]);

    return { lng, lat };
  }

  _latLngToWorld({ lat, lng }) {
    const { driveInfo } = this.props;
    const { driveUnitsToMeters } = driveInfo;
    const [x, y] = this.projector.inverse([lng, lat]);

    return {
      x: x * driveUnitsToMeters,
      y: y * driveUnitsToMeters,
    }
  }

  dragStart() {
    const { onChangeStart } = this.props;
    this.setState({ dragging: true }, onChangeStart);
  }

  handleDrag(canvas, isEnd = false) {
    const { driveInfo } = this.props;
    let relative = this.toRelativeFromCanvas(canvas);
    this.setState({
      markerLocalPosition: relative,
      dragging: !isEnd,
    });

    if (isEnd) {
      const { onChangeEnd } = this.props;
      onChangeEnd();
      // Query for photos near the marker.
      const world = this.toWorldFromRelative(relative);

      api.getPhotoByLocation(this._getLatLng(world), 10, 50, driveInfo._id)
        .then((photoInfo) => {
          let panoLatLng = null;

          if (photoInfo && photoInfo.photos.length) {
            const { latitude, longitude } = photoInfo.photos[0];
            // Update marker position to match the photo we're loading.
            panoLatLng = { lat: latitude, lng: longitude };
            relative = this.toRelativeFromWorld(this._latLngToWorld(panoLatLng));
          }

          this.setState({
            panoLatLng,
            photoInfo,
            photoIndex: 0,
            markerLocalPosition: relative,
          });
        });
    }
  }

  // 'Relative' is 0-1 in the image.
  toCanvasFromRelative({ x, y }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.props;

    return {
      x: imageX + imageWidth * x,
      y: imageY + imageHeight * y,
    };
  }

  toRelativeFromCanvas({ x, y }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.props;

    return {
      x: (x - imageX) / imageWidth,
      y: (y - imageY) / imageHeight,
    };
  }

  // 'World' is within the rectangular world dimensions.
  toWorldFromRelative({ x, y }) {
    const { worldRef } = this.props;
    const { height, width, bottom, left } = getWorldDimensions(worldRef);

    return {
      x: left + x * width,
      y: bottom + (1 - y) * height,
    };
  }

  toRelativeFromWorld({ x, y }) {
    const { worldRef } = this.props;
    const { height, width, bottom, left } = getWorldDimensions(worldRef);

    return {
      x: (x - left) / width,
      y: 1 - (y - bottom) / height,
    };
  }

  getPanoURL(photo, lod) {
    const lods = photo.lods ? [...photo.lods, photo.path] : [photo.path];
    return api.getStaticAssetURL(`${photo.driveId}/${lods[Math.min(lod, lods.length - 1)]}`, auth.getToken());
  }
  
  onPanoIndexChanged(index) {
    const { photoInfo } = this.state;
    const newPos = this.toRelativeFromWorld(this._latLngToWorld({
      lat: photoInfo.photos[index].latitude,
      lng: photoInfo.photos[index].longitude,
    }));

    this.setState({
      photoIndex: index,
      markerLocalPosition: newPos,
    });
  }

  onPanoViewChanged(dir, fov, pos) {
    const { onCameraChange } = this.props;
    const { markerLocalPosition } = this.state;

    onCameraChange(dir, fov, markerLocalPosition);
  }

  componentDidUpdate(prevProps) {
    const { proposedCameraPosition } = this.props;
    if (!proposedCameraPosition) return;

    if (prevProps.proposedCameraPosition && prevProps.proposedCameraPosition.x === proposedCameraPosition.x && prevProps.proposedCameraPosition.y === proposedCameraPosition.y) return;

    this.handleDrag(this.toCanvasFromRelative(proposedCameraPosition), true);
  }

  render() {
    const { onChangeEnd, onChangeStart } = this.props;
    const {
      markerLocalPosition,
      photoInfo,
      photoIndex,
      panoLatLng,
    } = this.state;

    return (
      <>
        {photoInfo && panoLatLng && (
          <div
            className={styles.container}
            onMouseEnter={onChangeStart}
            onMouseLeave={onChangeEnd}
          >
            <div className={styles.pano_overlay}>
              <Pano
                calibrations={photoInfo.calibrations}
                getPhotoURL={this.getPanoURL}
                panoIndex={photoIndex}
                photos={photoInfo.photos}
                onViewChange={this.onPanoViewChanged}
                photoCollections={photoInfo.photoCollections}
                doProgressiveLoading={true}
                requestedLOD={4}
              >
                <NavMarkers onClick={this.onPanoIndexChanged} />
              </Pano>
            </div>
            <a
              className={styles.coord_inlay}
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${panoLatLng.lat.toFixed(7)},${panoLatLng.lng.toFixed(7)}`}
            >
              {panoLatLng.lat.toFixed(5)}
              {', '}
              {panoLatLng.lng.toFixed(5)}
            </a>
          </div>
        )}
        <WorldPointOverlay
          onClick={NO_OP_FN}
          onDrag={this.handleDrag}
          onDragEnd={this.handleDrag}
          onDragStart={this.dragStart}
          onHover={NO_OP_FN}
          position={this.toCanvasFromRelative(markerLocalPosition)}
        />
      </>
    );
  }
}

