import React from 'react';
import { Link } from 'react-router-dom';
import IconChevronDown from './IconChevronDown';
import IconChevronLeft from './IconChevronLeft';
import IconChevronRight from './IconChevronRight';
import IconChevronUp from './IconChevronUp';
import ImageListDropdown from './ImageListDropdown';
import styles from './ImageList.module.css';

const linkTo = (image) => {
  if (!image) return '';

  return `/projects/${image.projId}/images/${image._id}`;
};

export default function ImageList({
  activeIndex,
  filters,
  images,
  isOpen,
  labels,
  projectType,
  toggleList,
}) {
  const prevImage = images[activeIndex - 1];
  const nextImage = images[activeIndex + 1];
  const handleToggleClick = (event) => {
    event.stopPropagation();
    toggleList();
  };

  return (
    <div className={styles.container}>
      <div className={styles.linkRow}>
        <Link
          className={styles.prev}
          disabled={!prevImage}
          to={linkTo(prevImage)}
        >
          <IconChevronLeft />
        </Link>
        <button
          className={styles.counter}
          onClick={handleToggleClick}
          type="button"
        >
          Image {activeIndex + 1} of {images.length}
          {isOpen && <IconChevronUp />}
          {!isOpen && <IconChevronDown />}
        </button>
        <Link
          className={styles.next}
          disabled={!nextImage}
          to={linkTo(nextImage)}
        >
          <IconChevronRight />
        </Link>
      </div>
      {isOpen && (
        <ImageListDropdown
          activeIndex={activeIndex}
          filters={filters}
          images={images}
          labels={labels}
          projectType={projectType}
          toggleList={toggleList}
        />
      )}
    </div>
  );
}
