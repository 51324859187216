import React from 'react';
import IconArrowLeft from './IconArrowLeft';
import IconArrowRight from './IconArrowRight';
import styles from './SiblingNav.module.css';

export default function SiblingNav({ disabled, onClickPrev, onClickNext }) {
  return (
    <nav className={styles.nav}>
      <button
        className={styles.button}
        disabled={disabled}
        onClick={onClickPrev}
        type="button"
      >
        <IconArrowLeft />
      </button>
      <span>Rotate</span>
      <button
        className={styles.button}
        disabled={disabled}
        onClick={onClickNext}
        type="button"
      >
        <IconArrowRight />
      </button>
    </nav>
  );
}
