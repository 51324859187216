import React, { Component } from 'react';
import Page from './Page';
import { login } from '../actions/authActions';
import GoogleButton from '../components/GoogleButton';
import styles from './Login.module.css';

const ERR_MSG = 'Username or password is incorrect. Please try again.';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitting: false, error: null };

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();

    this.handleGoogleError = this.handleGoogleError.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleGoogleError(error) {
    this.setState({ error });
  }

  handleGoogleLogin() {
    this.redirect();
  }

  handleSubmit(event) {
    event.preventDefault();

    const { current: emailInput } = this.emailRef;
    const { current: passwordInput } = this.passwordRef;

    this.setState({ isSubmitting: true }, () => {
      login(emailInput.value, passwordInput.value)
        .then(() => this.redirect())
        .catch(() => {
          this.setState({ isSubmitting: false, error: ERR_MSG });
        });
    });
  }

  redirect() {
    const { history, location } = this.props;

    if (location.state && location.state.from) {
      history.push(location.state.from);
    } else {
      history.push('/');
    }
  }

  render() {
    const { error, isSubmitting } = this.state;

    return (
      <Page>
        <div className={styles.login}>
          <header>
            <h1 className={styles.heading}>
              Login
            </h1>
          </header>
          {error != null && <p className={styles.error}>{error}</p>}
          <form onSubmit={this.handleSubmit}>
            <fieldset className={styles.field}>
              <label htmlFor="email">
                Email
              </label>
              <input
                ref={this.emailRef}
                id="email"
                type="email"
                name="email"
                placeholder="email address"
              />
            </fieldset>
            <fieldset className={styles.field}>
              <label htmlFor="password">
                Password
              </label>
              <input
                ref={this.passwordRef}
                id="password"
                type="password"
                name="password"
                placeholder="password"
              />
            </fieldset>
            <button
              className={styles.submit}
              disabled={isSubmitting}
              type="submit"
            >
              Login
            </button>
            <GoogleButton
              onError={this.handleGoogleError}
              onLogin={this.handleGoogleLogin}
            />
          </form>
        </div>
      </Page>
    );
  }
}
