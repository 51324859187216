import { combineReducers } from 'redux';
import annotationsReducer, { initialState as annotations } from './annotationsReducer';
import dashboardReducer, { initialState as dashboard } from './dashboardReducer';
import filtersReducer, { initialState as filters } from './filtersReducer';
import imagesReducer, { initialState as images } from './imagesReducer';
import jobsReducer, { initialState as jobs } from './jobsReducer';
import labelingReducer, { initialState as labeling } from './labelingReducer';
import polylinesReducer, { initialState as polylines } from './polylinesReducer';
import projectsReducer, { initialState as projects } from './projectsReducer';

export const initialState = {
  annotations,
  dashboard,
  filters,
  images,
  jobs,
  labeling,
  polylines,
  projects,
};

export const reducer = combineReducers({
  annotations: annotationsReducer,
  dashboard: dashboardReducer,
  filters: filtersReducer,
  images: imagesReducer,
  jobs: jobsReducer,
  labeling: labelingReducer,
  polylines: polylinesReducer,
  projects: projectsReducer,
});
