import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import App from './App';
import 'simplebar/dist/simplebar.css';
import './main.css';

const root = document.getElementById('root');

Modal.setAppElement(root);

ReactDOM.render(<App />, root);
