import React from 'react';
import IconClose from './IconClose';
import IconEye from './IconEye';
import IconTrash from './IconTrash';
import LabelSelect from './LabelSelect';
import styles from './PolylineListItem.module.css';

const PolylineDefaults = {
  'Road Paint': { styles: [ 'Solid', 'Dashed', 'Block Marking' ], aoiWidth: 0.1524 },
  'Barrier': { styles: [ 'Guide Rail', 'Concrete', 'Fence', 'Wall', 'Plastic', 'Invalid' ], aoiWidth: null },
  'Obstruction': { styles: [ 'Lateral' ], aoiWidth: null },
};

const metersToInches = (meters) => parseInt(0.5 + meters * 39.3701);
const inchesToMeters = (inches) => inches / 39.3701;

export default function PolylineListItem({
  activeIndex,
  index,
  labels,
  lastWidth,
  onChangeAoi,
  onChangeLabel,
  onChangeStyle,
  onDelete,
  onToggle,
  polyline,
  onClick,
}) {
  const isActive = activeIndex === index;

  let polyStyle = polyline.style;
  let aoiWidth = polyline.aoiWidthWorld;

  const defaults = PolylineDefaults[labels[polyline.label].label];
  let styleSel = null;
  if (defaults) {
    const styleChange = (style) => {
      onChangeStyle(style, index);
    };

    polyStyle = polyStyle || defaults.styles[0];

    styleSel = (
      <select onClick={(event)=>event.stopPropagation()} onChange={(event)=>styleChange(event.target.value)} value={polyStyle}>
        { defaults.styles.map((s,is)=>( <option key={ 'styleopt_' + is }>{ s }</option> )) }
      </select>
    );
  }

  if (!aoiWidth && defaults) {
    aoiWidth = defaults.aoiWidth;
  }

  let uuidInfo = null;
  if (isActive) uuidInfo = polyline.extractionUUID;

  const marker = polyline.edited ? '**' : '';

  return (
    <div className={`${styles.row} ${isActive ? styles.rowActive : ''}`} onClick={()=>onClick(index)}>
      <header className={styles.header}>
        <h5 >
          { marker } Polyline {index}<br />
          { uuidInfo }
        </h5>
        <div>
          <button
            type="button"
            className={styles.trash}
            onClick={() => onDelete(index)}
          >
            <IconTrash />
          </button>
          <button
            type="button"
            className={styles.visible}
            onClick={() => onToggle(index)}
          >
            {polyline.visible ? <IconEye /> : <IconClose />}
          </button>
        </div>
      </header>
      <div className={styles.inputRow}>
        <LabelSelect
          labels={labels}
          onChange={label => onChangeLabel(label, index)}
          value={polyline.label}
        />
        { polyStyle && styleSel }
        { aoiWidth && (
          <>
            <input
              type="text"
              onBlur={({ target }) => onChangeAoi(inchesToMeters(Number(target.value)), index)}
              defaultValue={ metersToInches(aoiWidth)}
              onClick={(event)=>event.stopPropagation()}
            />
            <span>in.</span>
          </>
        )}
      </div>
    </div>
  );
}
