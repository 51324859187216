import React from 'react';
import { Link } from 'react-router-dom';
import IconChevronDown from './IconChevronDown';
import IconChevronUp from './IconChevronUp';
import IconExport from './IconExport';
import { auth } from '../utils';
import styles from './DashboardListItem.module.css';



export default class DashboardButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { allowPublish: true };
  }

  renderExportButton({ children }) {
    const { handleClickExport } = this.props;
    return (
      <button className={styles.exportBtn} onClick={handleClickExport}>
        Export
        {children}
      </button>
    );
  }

  renderViewButton() {
    const { handleClickView, project } = this.props;
    const path = `/projects/${project._id}/images`;
    return (
      <Link className={styles.viewBtn} onClick={handleClickView} to={path}>
        View
      </Link>
    );
  }

  renderMainButton() {
    const { isQcProject, handleClickView, handleClickPublish, isReviewComplete, isComplete, project } = this.props;
    const { allowPublish } = this.state;

    const path = `/projects/${project._id}/images`;

    if (isQcProject) {
      if (!isReviewComplete) {
        return (
          <Link className={styles.reviewBtn} onClick={handleClickView} to={path}>
            Review
          </Link>
        );
      }

      const isAdmin = auth.isAdmin();
      if (isAdmin && isReviewComplete && !isComplete) {
        if (allowPublish) {
          return (
            <button className={styles.publishBtn} onClick={(e)=>{ this.setState({ allowPublish: false}, ()=>handleClickPublish(e)) } } >
              Publish
            </button>
          );
        }
        else return (
          <button className={styles.publishBtn_disabled} disabled >
              Publish
            </button>
        );
      }

      return this.renderExportButton(<IconExport />);
    }

    return this.renderViewButton();
  }

  renderMenu() {
    const { isMenuOpen, menuRef, isQcProject, isComplete, isReviewComplete } = this.props;
    if (!isMenuOpen) return null;

    const items = [
      this.renderExportButton(<IconExport />)
    ];

    if (isQcProject) {
      if (isReviewComplete) {
        items.unshift(this.renderViewButton());
      }
      if (isComplete && items.length === 2) {
        items.pop();
      }
    }

    return (
      <ul ref={menuRef} className={styles.menu}>
        {items.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    );
  };

  render() {
    const {
      isMenuOpen,
      isProcessing,
      project,
      toggleMenu,
    } = this.props;

    if (isProcessing) {
      return (
        <button disabled className={styles.processingBtn}>
          processing...
        </button>
      );
    }
  
    if (!project) return null;
  
    return (
      <>
        {this.renderMainButton()}
        <button className={styles.menuBtn} onClick={toggleMenu}>
          {isMenuOpen && <IconChevronUp title="Hide Actions" />}
          {!isMenuOpen && <IconChevronDown title="Show Actions" />}
        </button>
        {this.renderMenu()}
      </>
    );
  }
};

