import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as annotationActions from '../actions/annotationActions';
import * as imageActions from '../actions/imageActions';
import AnnotationOverlay from './AnnotationOverlay';
import { clamp } from '../utils';

const COLORS = [
  'rgba(230, 25, 75, 0.2)',
  'rgba(60, 180, 75, 0.2)',
  'rgba(255, 225, 25, 0.2)',
  'rgba(0, 130, 200, 0.2)',
  'rgba(245, 130, 48, 0.2)',
  'rgba(145, 30, 180, 0.2)',
  'rgba(70, 240, 240, 0.2)',
  'rgba(240, 50, 230, 0.2)',
  'rgba(210, 245, 60, 0.2)',
  'rgba(250, 190, 190, 0.2)',
  'rgba(0, 128, 128, 0.2)',
  'rgba(230, 190, 255, 0.2)',
  'rgba(170, 110, 40, 0.2)',
  'rgba(255, 250, 200, 0.2)',
  'rgba(128, 0, 0, 0.2)',
  'rgba(170, 255, 195, 0.2)',
  'rgba(128, 128, 0, 0.2)',
  'rgba(255, 215, 180, 0.2)',
  'rgba(0, 0, 128, 0.2)',
  'rgba(128, 128, 128, 0.2)',
];

const findLabel = (labels, labelId) => labels.find(({ id }) => id === labelId);

const getLabelColor = (label, isSelected = false) => {
  if (isSelected) {
    return 'transparent';
  }

  const index = label % COLORS.length;

  return COLORS[index];
};

class AnnotationOverlays extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderOverlay = this.renderOverlay.bind(this);
  }

  handleChange(changes, index) {
    const {
      annotations,
      imageHeight,
      imageWidth,
      imageX,
      imageY,
      onChangeEnd,
      updateAnnotation,
      updateAnnotationSize,
    } = this.props;
    const current = annotations[index];
    let { width, height } = changes;

    if (width == null || height == null) {
      ({ width, height } = this.getSize(current));
    }

    const maxX = (imageX + imageWidth) - width;
    const left = clamp(changes.x, imageX, maxX);

    const maxY = (imageY + imageHeight) - height;
    const top = clamp(changes.y, imageY, maxY);

    const updates = {
      left: clamp((left - imageX) / imageWidth, 0, 1),
      top: clamp((top - imageY) / imageHeight, 0, 1),
      height: clamp(height / imageHeight, 0, 1),
      width: clamp(width / imageWidth, 0, 1),
    };

    updateAnnotation(index, updates);
    updateAnnotationSize(updates);
    onChangeEnd();
  }

  handleClick(index) {
    this.props.setActiveAnnotation(index);
  }

  getLabel(label, index, isSelected = false) {
    if (!isSelected) return '';

    const { labels } = this.props;
    const found = findLabel(labels, label) || { label: '' };

    return `${found.label} (${index + 1})`;
  }

  getPosition({ left, top }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.props;

    return { x: imageX + imageWidth * left, y: imageY + imageHeight * top };
  }

  getSize({ height, width }) {
    const { imageHeight, imageWidth } = this.props;

    return { height: imageHeight * height, width: imageWidth * width };
  }

  renderOverlay(annotation, index) {
    const {
      activeIndex,
      imageHeight,
      imageWidth,
      onChangeStart,
    } = this.props;
    const isSelected = activeIndex === index;

    return (
      <AnnotationOverlay
        key={annotation._id}
        backgroundColor={getLabelColor(annotation.label, isSelected)}
        index={index}
        isSelected={isSelected}
        label={this.getLabel(annotation.label, index, isSelected)}
        maxHeight={imageHeight}
        maxWidth={imageWidth}
        onChangeStart={onChangeStart}
        onChangeEnd={this.handleChange}
        onClick={this.handleClick}
        position={this.getPosition(annotation)}
        size={this.getSize(annotation)}
      />
    );
  }

  render() {
    const { annotations } = this.props;

    return annotations.map(this.renderOverlay);
  }
}

const actions = {
  setActiveAnnotation: annotationActions.setActiveAnnotation,
  updateAnnotation: imageActions.updateImageAnnotation,
  updateAnnotationSize: annotationActions.updateCurrentSize,
};

export default connect(null, actions)(AnnotationOverlays);
