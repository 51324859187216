import React, { Component } from 'react';
import Modal from 'react-modal';

import IconClose from './IconClose';

import styles from './ProcessingJobs.module.css';

const isSubmitDisabled = (form, ingest) => {
  if (ingest) return !form.src_path || !form.tgt_path;
  else return !form.presets;
}

export default class ProcessingJobForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    this.setState((state) => ({ ...state, [target.name]: target.value }));
  }

  handleMultiSelectChange({ target }) {
    this.setState((state) => ({ ...state, [target.name]: [...target.options].filter(opt=>opt.selected).map(o=>o.value) }));
  }

  scrub() {
    const formData = { ...this.state, beacon_drive_id: this.props.driveId };

    if (formData.src_path && formData.src_path[formData.src_path.length-1] !== '/') {
      formData.src_path += '/';
    }
    if (formData.tgt_path && formData.tgt_path[formData.tgt_path.length-1] !== '/') {
      formData.tgt_path += '/';
    }
    return formData;
  }

  handleSubmit(event) {
    const { onSubmit } = this.props;
    const formData = this.scrub();

    if (this.state.presets) {
      formData.presets = [this.state.presets];
    }

    event.preventDefault();
    onSubmit(formData);
    this.setState(() => {
      const nextState = {};
      Object.keys(formData).forEach((key) => {
        nextState[key] = '';
      });
      return nextState;
    });
  }

  renderDataSourceOption(ds, ind) {
    return (
      <option key={ "datasource_" + ind } value={ ds._id }>{ ds.name }</option>
    )
  }

  render() {
    const { closeModal, isOpen, driveId, datasources } = this.props;
    const form = this.state;

    const isIngestion = !driveId;
    const titleStr = isIngestion ? "Create Ingestion Job" : "Create Extraction Job";

    return (
      <Modal
        className={styles.modalContent}
        isOpen={isOpen}
        overlayClassName={styles.overlay}
      >
        <header className={styles.modalHeader}>
          <h4>{ titleStr }</h4>
          <button onClick={closeModal} type="button">
            <IconClose />
          </button>
        </header>
        <form
          className={styles.form}
          onSubmit={this.handleSubmit}
          ref={this.formRef}
        >
          { driveId && (
            <fieldset>
            <label htmlFor="driveId">
              Drive ID
            </label>
            <input
              type="text"
              id="driveId"
              name="driveId"
              placeholder='Drive ID'
              onChange={this.handleChange}
              value={form.driveId || driveId.toString()}
              autoFocus
              readOnly
              required
            />
            </fieldset>
          )}
          <fieldset>
            <label htmlFor="projectName">
              Project Name
            </label>
            <input
              type="text"
              id="projectName"
              name="projectName"
              placeholder="Project Name"
              onChange={this.handleChange}
              value={form.projectName || ''}
              autoFocus
            />
          </fieldset>
          { isIngestion && (<>
          <fieldset>
            <label htmlFor="src_path">
              Source Path <small>*required</small>
            </label>
            <input
              type="text"
              id="src_path"
              name="src_path"
              placeholder="path/to/source"
              onChange={this.handleChange}
              value={form.src_path || ''}
              required
            />
          </fieldset>
          <fieldset>
            <label htmlFor="tgt_path">
              Target Path <small>*required</small>
            </label>
            <input
              type="text"
              id="tgt_path"
              name="tgt_path"
              placeholder="path/to/target"
              onChange={this.handleChange}
              value={form.tgt_path || ''}
              required
            />
          </fieldset>
          <fieldset>
            <label htmlFor="config_path">
              Config Path
            </label>
            <input
              type="text"
              id="config_path"
              name="config_path"
              placeholder="./config.json (default)"
              onChange={this.handleChange}
              value={form.config_path || ''}
            />
          </fieldset>
          </>)}
          { !isIngestion && (
            <>
          <fieldset>
            <label htmlFor="presets">
              Preset <small>*required</small>
            </label>
            <select
              id="presets"
              name="presets"
              onChange={this.handleChange}
              value={form.presets || ''}
              required
            >
              <option value="">-- Choose a preset --</option>
              <option value="CITY_ASSETS">City Assets</option>
              <option value="PARKING">Parking</option>
              <option value="RAIL_ASSETS">Rail Assets</option>
              <option value="ROAD_ASSETS">Road Assets</option>
              <option value="ROAD_PAINT">Road Paint</option>
              <option value="CURBS">Curbs</option>
              <option value="SAFETY_BARRIERS">Safety Barriers</option>
              <option value="POLES">Poles</option>
            </select>
          </fieldset>
          <fieldset>
            <label htmlFor="presets">
              Baseline Data Sources
            </label>
            <select
              multiple={true}
              id="baseline_sources"
              name="baseline_sources"
              onChange={this.handleMultiSelectChange}
              value={form.baseline_sources || []}
            >
              { datasources.map(this.renderDataSourceOption) }
            </select>
          </fieldset>
          </>
          )}
          <fieldset>
            <label htmlFor="docker_tag">
              Docker Tag
            </label>
            <input
              type="text"
              id="docker_tag"
              name="docker_tag"
              placeholder="latest (default)"
              onChange={this.handleChange}
              value={form.docker_tag || ''}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="purpose">
              Purpose <small>*required</small>
            </label>
            <select
              id="purpose"
              name="purpose"
              onChange={this.handleChange}
              value={form.purpose || ''}
              required
            >
              <option value="">-- Choose a purpose --</option>
              <option value="dev">dev</option>
              <option value="test">test</option>
              <option value="prod">prod</option>
            </select>
          </fieldset>
          <fieldset>
            <label htmlFor="project_code">
              Project Code
            </label>
            <input
              type="text"
              id="project_code"
              name="project_code"
              placeholder="project code"
              onChange={this.handleChange}
              value={form.project_code || ''}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="operator_email">
              User Email
            </label>
            <input
              type="text"
              id="operator_email"
              name="operator_email"
              placeholder="operator email"
              onChange={this.handleChange}
              value={form.operator_email || ''}
            />
          </fieldset>
        </form>
        <footer className={styles.modalFooter}>
          <button
            className={styles.cancelButton}
            onClick={closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className={styles.submitButton}
            disabled={isSubmitDisabled(form, isIngestion)}
            onClick={this.handleSubmit}
            type="button"
          >
            Submit
          </button>
        </footer>
      </Modal>
    );
  }
}
