import React, { PureComponent } from 'react';
import { Rnd } from 'react-rnd';
import styles from './AnnotationOverlay.module.css';

const HANDLE_CLASSES = {
  bottomLeft: styles.handleCorner,
  bottomRight: styles.handleCorner,
  topLeft: styles.handleCorner,
  topRight: styles.handleCorner,
};
const HANDLE_STYLES = {
  bottomLeft: { bottom: -5, left: -5, height: 10, width: 10 },
  bottomRight: { bottom: -5, right: -5, height: 10, width: 10 },
  topLeft: { top: -5, left: -5, height: 10, width: 10 },
  topRight: { top: -5, right: -5, height: 10, width: 10 },
};

export default class AnnotationOverlay extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
  }

  onClick(event) {
    const { index, onClick } = this.props;

    event.stopPropagation();
    onClick(index);
  }

  onDragStop(event, dragEvent) {
    const changes = { x: dragEvent.x, y: dragEvent.y };

    this.updateAnnotation(changes);
  }

  onResizeStop(event, dir, refToElement, delta, pos) {
    const { size } = this.props;
    const changes = {
      height: size.height + delta.height,
      width: size.width + delta.width,
      x: pos.x,
      y: pos.y,
    };

    this.updateAnnotation(changes);
  }

  updateAnnotation(changes) {
    const { index, onChangeEnd } = this.props;

    onChangeEnd(changes, index);
  }

  render() {
    const {
      backgroundColor,
      isSelected,
      label,
      maxHeight,
      maxWidth,
      onChangeStart,
      position,
      size,
    } = this.props;

    return (
      <Rnd
        className={isSelected ? styles.selected : styles.annotation}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        onClick={this.onClick}
        onDragStart={onChangeStart}
        onDragStop={this.onDragStop}
        onResizeStart={onChangeStart}
        onResizeStop={this.onResizeStop}
        position={position}
        resizeHandleClasses={HANDLE_CLASSES}
        resizeHandleStyles={HANDLE_STYLES}
        size={size}
        style={{ backgroundColor }}
      >
        <div className={styles.label}>
          {label}
        </div>
      </Rnd>
    );
  }
}
