import React from 'react';
import styles from './Icon.module.css';

export default function IconChevronDown({ title }) {
  return (
    <svg className={styles.icon} viewBox="0 0 20 20">
      <title>{title || 'down'}</title>
      <path d="M0 6c0-0.128 0.049-0.256 0.146-0.354 0.195-0.195 0.512-0.195 0.707 0l8.646 8.646 8.646-8.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-9 9c-0.195 0.195-0.512 0.195-0.707 0l-9-9c-0.098-0.098-0.146-0.226-0.146-0.354z" />
    </svg>
  );
}
