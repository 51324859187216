import loadGoogleApi from 'google-api-load';
import storage from './storage';

const STORAGE_KEY = 'av_user';

function getUser() {
  const user = storage.getItem(STORAGE_KEY);

  if (user) {
    return JSON.parse(user);
  }

  return {};
}

function parseToken(user = {}) {
  return user.jwt;
}

function setUser(user) {
  storage.setItem(STORAGE_KEY, JSON.stringify(user));
}

export function getToken() {
  return getUser().jwt;
}

export function initGoogleAuth() {
  return loadGoogleApi()
    .then((gapi) => {
      gapi.load('auth2', () => {
        gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID });
      });
    })
    .catch((err) => {
      console.error('Google API failed to load', err);
    });
}

export function isAdmin() {
  return !!getUser().isAdmin;
}

export function isAuthenticated() {
  return !!getToken();
}

export function setCurrentUser(user) {
  setUser(user);
  return parseToken(user);
}

export function unsetCurrentUser() {
  storage.removeItem(STORAGE_KEY);
}

export default {
  getToken,
  getUser,
  initGoogleAuth,
  isAdmin,
  isAuthenticated,
  setCurrentUser,
  unsetCurrentUser,
};
