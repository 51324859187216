import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as annotationActions from '../actions/annotationActions';
import * as polylineActions from '../actions/polylineActions';
import { pluralize } from '../utils';
import Box from './Box';
import PolylineListItem from './PolylineListItem';

class PolylinesList extends Component {
  constructor(props) {
    super(props);

    this.listRef = React.createRef();
    this.state = { scrollTop: 0 };

    this.onChangeAoi = this.onChangeAoi.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onChangeStyle = this.onChangeStyle.bind(this);
  }

  componentDidUpdate({ activeIndex: prevActive }) {
    const { activeIndex } = this.props;

    if (activeIndex == null || activeIndex === prevActive) return;

    const list = this.listRef.current;

    if (!list) return;

    // If we have a new active line, scroll to it.
    const box = list.parentElement.parentElement;
    const item = list.children.item(activeIndex);

    if (box && item) {
      const boxRect = box.getBoundingClientRect();
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top >= 0 && itemRect.bottom <= (boxRect.top + boxRect.height)) {
        return;
      }

      this.setState({ scrollTop: item.offsetTop });
    }
  }

  onChangeAoi(aoiWidth, index) {
    const { updatePolyline, updatePolylineWidth } = this.props;

    updatePolyline(index, { aoiWidth })
    updatePolylineWidth(aoiWidth);
  }

  onChangeLabel(label, index) {
    const { updateCurrentLabel, updatePolyline } = this.props;

    updatePolyline(index, { label });
    updateCurrentLabel(label);
  }

  onChangeStyle(style, index) {
    const { updatePolyline } = this.props;
    updatePolyline(index, { style });
  }

  render() {
    const {
      activeIndex,
      deletePolyline,
      labels,
      lastPolyWidth,
      onClick,
      polylines,
      toggleVisibility,
    } = this.props;
    const { length: count } = polylines;
    const { scrollTop } = this.state;

    return (
      <Box
        small
        scrollTop={scrollTop}
        title={`${count} ${pluralize(count, 'Global Object')}`}
      >
        <ul ref={this.listRef}>
          {polylines.map((polyline, index) => (
            <li key={polyline._id}>
              <PolylineListItem
                activeIndex={activeIndex}
                index={index}
                labels={labels}
                lastWidth={lastPolyWidth}
                polyline={polyline}
                onChangeAoi={this.onChangeAoi}
                onChangeLabel={this.onChangeLabel}
                onChangeStyle={this.onChangeStyle}
                onClick={onClick}
                onDelete={deletePolyline}
                onToggle={toggleVisibility}
              />
            </li>
          ))}
        </ul>
      </Box>
    );
  }
}

const actions = {
  deletePolyline: polylineActions.deleteActivePolyline,
  toggleVisibility: polylineActions.togglePolylineVisible,
  updateCurrentLabel: annotationActions.updateCurrentLabel,
  updatePolyline: polylineActions.updateActivePolyline,
  updatePolylineWidth: polylineActions.updatePolylineWidth,
  onClick: polylineActions.setActivePolyline,
};

export default connect(null, actions)(PolylinesList);
