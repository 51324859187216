import qs from 'query-string';

const LOCAL_URL = 'beacon-local';
export function getApiUrl(context) {
  const url = process.env.REACT_APP_API_URL;

  if (url.includes(LOCAL_URL)) {
    const { hostname, protocol } = context.location;
    return `${protocol}//${url.replace(LOCAL_URL, hostname)}`;
  }

  return url;
}

export class API {
  constructor(client) {
    this.client = client;

    this.handleError = this.handleError.bind(this);
  }

  handleError(err) {
    if (this.errorHandler) {
      this.errorHandler(err);
    }
    throw err;
  }

  setErrorHandler(fn) {
    if (typeof fn === 'function') {
      this.errorHandler = fn;
    }
  }

  // Auth

  clearAuthToken() {
    this.client.setToken(null);
  }

  updateAuthToken(token) {
    if (!token) {
      this.clearAuthToken();
      return;
    }

    this.client.setToken(token);
  }

  login(email, password) {
    const body = { username: email, password };

    return this.client.post('/auth', { body });
  }

  loginGoogle(token) {
    const body = { token };

    return this.client.post('/auth/google', { body });
  }

  checkUserAccount() {
    return this.client.get('/auth').catch(this.handleError);
  }

  getLabelingImageURL(token, imageId, siblingIndex) {
    const query = { token };

    if (siblingIndex != null) {
      query.sibling = siblingIndex;
    }

    return `${this.client.baseURL}/labeling/images/${imageId}/static?${qs.stringify(query)}`;
  }

  getLabelingImage(src) {
    return fetch(src)
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw response;
      })
      .catch(this.handleError);
  }

  getLabelingProjects() {
    return this.client.get('/labeling').catch(this.handleError);
  }

  getLabelingProject(id) {
    return this.client.get(`/labeling/${id}`).catch(this.handleError);
  }

  getProcessingJobs() {
    return this.client.get('/jobs').catch(this.handleError);
  }

  createProcessingJob(jobParams) {
    return this.client.post('/jobs', { body: jobParams })
      .catch(this.handleError);
  }

  advanceProcessingJob(id) {
    return this.client.post(`/jobs/${id}/advance`)
      .catch(this.handleError);
  }

  getProjectImages(projectId) {
    return this.client.get(`/labeling/${projectId}/images`)
      .catch(this.handleError);
  }

  getProjectImage(imageId) {
    return this.client.get(`/labeling/images/${imageId}`)
      .catch(this.handleError);
  }

  updateProjectImage(image) {
    return this.client.put(`/labeling/images/${image._id}`, { body: image })
      .catch(this.handleError);
  }

  getProjectPolylines(projectId) {
    return this.client.get(`/labeling/${projectId}/polylines`)
      .catch(this.handleError);
  }

  createProjectPolyline(projectId, body) {
    return this.client.post(`/labeling/${projectId}/polylines`, { body })
      .catch(this.handleError);
  }

  updateProjectPolyline(polyline) {
    return this.client.put(`/labeling/polylines/${polyline._id}`, {
      body: polyline,
    })
      .catch(this.handleError);
  }

  getProfileImageURL(token) {
    return `${this.client.baseURL}/account/image?token=${token}`;
  }

  getPhotoByLocation(latLng, limit=5, radius=50, driveId) {
    const url = `/drives/${ driveId }/photos/${latLng.lat},${latLng.lng}?limit=${ limit }&radius=${ radius }`;
    const config = {
      headers: {
        'X-Api-Version': 1.0,
        'Content-Type': 'application/json',
      },
    };
    return this.client.get(url, config);
  }

  // Static Assets
  getStaticAssetURL(path, token) {
    return `${this.client.baseURL}/static?token=${token}&path=${path}`;
  }

  getUnitScale(code) {
    if (code === 1)
    {
        return 1.0 / 3.28083333333;
    }
    else if (code === 2)
    {
        return 0.3048;
    }
    else return 1.0;
  }

  getDriveInfo(id) {
    const url = '/drives/' + id;
    return this.client.get(url)
    .then((drive) => {
      if (drive.displayUnits == null) {
        drive.displayUnits = drive.units;
      }
      drive.displayUnitsToMeters = this.getUnitScale(drive.displayUnits);
      drive.driveUnitsToMeters = this.getUnitScale(drive.units);
      drive.extractionUnitsToMeters = (drive.extractionUnits != null) ? this.getUnitScale(drive.extractionUnits) : 1.0;
      if (drive.latLngTargetSystem == null) {
        // const WGS84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        drive.latLngTargetSystem = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
      }
      return drive;
    });
  }

  getUserDataSources() {
    const url = '/datasources';
    return this.client.get(url);
  }
}
