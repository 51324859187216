import panzoom from 'panzoom';

function getCenterTransform(element) {
  const { clientHeight, clientWidth } = element;
  const { height, width } = element.parentElement.getBoundingClientRect();
  const dh = height / clientHeight;
  const dw = width / clientWidth;
  const scale = Math.min(dh, dw);
  const x = -(clientWidth / 2) * scale + width / 2;
  const y = -(clientHeight / 2) * scale + height / 2;

  return { x, y, zoom: scale };
}

export default class Panzoom {
  constructor(element, onTransform) {
    Object.defineProperties(this, {
      element: { value: element },
      panzoom: {
        value: panzoom(element, {
          bounds: false,
          maxZoom: 25,
          minZoom: 0.25,
          zoomDoubleClickSpeed: 1,
          zoomSpeed: 0.1,
        }),
      },
    });

    if (typeof onTransform === 'function') {
      this.panzoom.on('transform', onTransform);
    }

    this.center();
  }

  center() {
    const { x, y, zoom } = getCenterTransform(this.element);

    this.panzoom.moveTo(x, y);
    this.panzoom.zoomAbs(0, 0, zoom);
  }

  dispose() {
    this.panzoom.dispose();
  }

  getTransform() {
    return this.panzoom.getTransform();
  }

  pause() {
    if (this.panzoom.isPaused()) return;

    this.panzoom.pause();
  }

  resume() {
    if (this.panzoom.isPaused()) {
      this.panzoom.resume();
    }
  }
}
