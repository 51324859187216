export function error(...args) {
  console.error(...args);
}

export function log(...args) {
  console.log(...args);
}

export function warn(...args) {
  console.warn(...args)
}

export default {
  error,
  log,
  warn,
};
