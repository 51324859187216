import { v4 as uuidv4 } from 'uuid';
import { projectStore } from '../data';

export function groupProjects() {
  const groups = new Map();
  const projects = projectStore.list();

  projects.forEach((project) => {
    const { projectType = 'Labeling' } = project;
    const group = groups.get(projectType) || [];

    group.push({ ...project, projectType });

    groups.set(projectType, group);
  });

  return Array.from(groups).sort(([left], [right]) => left.localeCompare(right));
}

export function isQcProject({ projectType }) {
  return projectType === 'QC';
}

export function parseProject(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      let json;

      try {
        json = JSON.parse(target.result);
      }
      catch {
        return reject(new Error('This is not a JSON file.'));
      }

      if (!Array.isArray(json.ImageList)) {
        return reject(new Error('Project is not properly formatted.'));
      }

      json._id = uuidv4();
      json.annotationCt = 0;
      json.imageCt = json.ImageList.length;
      json.reviewedCt = 0;

      json.ImageList.forEach((image) => {
        if (image.reviewed) {
          json.reviewedCt += 1;
        }

        image._id = uuidv4();
        json.annotationCt += image.annotations.length;
      });

      resolve(json);
    };

    reader.readAsText(file);
  });
}
