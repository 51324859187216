import React from 'react';
import { api, auth } from '../utils';
import styles from './UserInfo.module.css';

export default function UserInfo() {
  const user = auth.getUser();

  if (!user) return <div />;

  return (
    <div className={styles.container}>
      <img src={api.getProfileImageURL(auth.getToken())} alt="Profile" crossOrigin={"anonymous"} />
      <p>{user.username}</p>
    </div>
  );
}
