class ResponseError extends Error {
  constructor(message, { headers, status, statusText, url } = {}) {
    super(message);

    this.headers = headers;
    this.status = status;
    this.statusText = statusText;
    this.url = url;
  }
}

export default class Client {
  constructor(baseURL = '') {
    Object.defineProperties(this, {
      baseURL: {
        value: baseURL,
      },
      headers: {
        value: new Headers({ 'Content-Type': 'application/json' }),
      },
    });

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  setToken(token) {
    if (token) {
      this.headers.set('Authorization', `Bearer ${token}`);
    } else if (this.headers.has('Authorization')) {
      this.headers.delete('Authorization');
    }
  }

  get(url, options = {}) {
    return this.makeRequest(url, { ...options, method: 'GET' });
  }

  post(url, options = {}) {
    const { body, ...rest } = options;

    return this.makeRequest(url, {
      ...rest,
      body: JSON.stringify(body),
      method: 'POST',
    });
  }

  put(url, options = {}) {
    const { body, ...rest } = options;

    return this.makeRequest(url, {
      ...rest,
      body: JSON.stringify(body),
      method: 'PUT',
    });
  }

  delete(url, options = {}) {
    return this.makeRequest(url, { ...options, method: 'DELETE' });
  }

  makeRequest(path, options = {}) {
    const { query = '', ...fetchOptions } = options;

    let headers = this.headers;
    if (options.headers) {
      headers = new Headers(this.headers);
      Object.entries(options.headers).forEach(([name, value]) => {
        headers.append(name, value);
      });
    }

    let url = `${this.baseURL}${path.startsWith('/') ? path : `/${path}`}`;
    if (query) {
      url = `${url}?${query}`;
    }

    return fetch(url, {
      ...fetchOptions,
      headers,
      mode: 'cors',
      referrer: 'no-referrer',
      referrerPolicy: 'origin-when-cross-origin',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return response.json().then((err) => {
          const message = err.message || err;

          throw new ResponseError(message, response);
        });
      });
  }
}
