import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FocusProject from '../components/FocusProject';
import Images from './Images';

export default function ProjectRoute({ match }) {
  const { params, path, url } = match;

  return (
    <FocusProject projectId={params.projectId}>
      <Switch>
        <Route path={`${path}/images`} component={Images} />
        <Route path={path} render={() => <Redirect to={`${url}/images`} />} />
      </Switch>
    </FocusProject>
  );
}
