import React from 'react';
import IconBeaker from './IconBeaker';
import IconDocument from './IconDocument';
import IconMicroscope from './IconMicroscope';
import IconWarning from './IconWarning';
import IconCheck from './IconCheck';
import styles from './DashboardListItem.module.css';

export default function DashboardIcon({
  errorMessage,
  isProcessing,
  isQcProject,
  isReviewComplete,
  isComplete,
}) {
  if (errorMessage) {
    return <div className={styles.iconBgError}><IconWarning /></div>;
  }

  if (isProcessing) {
    return <div className={styles.iconBgProcessing}><IconBeaker /></div>;
  }

  if (isQcProject && !isReviewComplete) {
    return <div className={styles.iconBgReviewing}><IconMicroscope /></div>;
  }

  if (isComplete) {
    return <div className={styles.iconBgComplete}><IconCheck /></div>;
  }

  return <div className={styles.iconBgReviewComplete}><IconDocument /></div>;
}
