import { api, auth } from '../utils';

function handleAuthenticated(user) {
  const token = auth.setCurrentUser(user);
  api.updateAuthToken(token);
  return user;
}

function handleError(err) {
  if (err.status === 401) {
    logout();
  }
  throw err;
}

function checkUser() {
  if (auth.isAuthenticated()) {
    return api.checkUserAccount();
  }

  return Promise.reject(new Error('Log in to continue'));
}

export function init() {
  const token = auth.getToken();
  api.updateAuthToken(token);
  api.setErrorHandler(handleError);
  return checkUser();
}

export function login(username, password) {
  return api.login(username, password)
    .then(handleAuthenticated);
}

export function loginGoogle(token) {
  return api.loginGoogle(token)
    .then(handleAuthenticated);
}

export function logout() {
  api.clearAuthToken();
  auth.unsetCurrentUser();
}
