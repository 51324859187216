import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as imageActions from '../actions/imageActions';
import * as labelingActions from '../actions/labelingActions';
import * as polylineActions from '../actions/polylineActions';
import * as projectActions from '../actions/projectActions';
import { shouldToggle, toggleSurvey } from '../models/image';
import LabelingContainer from '../components/LabelingContainer';
import LabelingNavigation from '../components/LabelingNavigation';
import LabelingSidebar from '../components/LabelingSidebar';
import { api } from '../utils';

class LabelingPage extends Component {
  constructor(props) {
    super(props);

    this.state = { driveInfo: null };

    this.handleDriveInfo = this.handleDriveInfo.bind(this);
    this.toggleSurveyQuestion = this.toggleSurveyQuestion.bind(this);
  }

  componentDidMount() {
    const { project } = this.props;

    if (project.beaconDriveId) {
      api.getDriveInfo(project.beaconDriveId)
        .then(this.handleDriveInfo)
        .catch((err) => {
          console.error(err);
          console.log('Could not get drive info - probably no permissions?');
        });
    }
  }

  componentDidUpdate({
    activeImage: prevActive,
    imageChanged,
    polylinesChanged,
  }) {
    const { activeImage } = this.props;

    if (prevActive && activeImage && prevActive._id !== activeImage._id) {
      const { resetLabeling } = this.props;

      this.persistChanges(prevActive, imageChanged, polylinesChanged);
      resetLabeling();
    }
  }

  componentWillUnmount() {
    const {
      activeImage,
      imageChanged,
      polylinesChanged,
      resetLabeling,
    } = this.props;

    resetLabeling();
    this.persistChanges(activeImage, imageChanged, polylinesChanged);
    this.handleDriveInfo = () => {};
  }

  handleDriveInfo(driveInfo) {
    this.setState({ driveInfo });
  }

  toggleSurveyQuestion(index, yesOrNo) {
    const { activeImage, updateImage } = this.props;

    if (yesOrNo == null || shouldToggle(activeImage, index, yesOrNo)) {
      updateImage(toggleSurvey(activeImage, index));
    }
  }

  persistChanges(image, imageChanged, polylinesChanged) {
    if (!image.reviewed || imageChanged) {
      const { reloadProject, saveImage } = this.props;

      saveImage(image).then(() => reloadProject(image.projId));
    }

    if (polylinesChanged) {
      const { savePolylines } = this.props;
      savePolylines();
    }
  }

  render() {
    const {
      addAnnotation,
      activeAnnotationIndex,
      activeImage,
      activeImageIndex,
      activePolylineIndex,
      filters,
      imageGroups,
      images,
      isImageListOpen,
      lastPolyWidth,
      navigateTo,
      polylines,
      project,
      removeAnnotation,
      siblingIndex,
      toggleList,
      updateImage,
      updateSiblingIndex,
    } = this.props;
    const { driveInfo } = this.state;
    const labels = project.LabelMap;
    const { projectType } = project;

    // NOTE: adding a `key` to LabelingContainer below causes it to reset its
    // state when the key changes
    return (
      <LabelingNavigation
        activeAnnotationIndex={activeAnnotationIndex}
        activeImage={activeImage}
        activeIndex={activeImageIndex}
        addAnnotation={addAnnotation}
        filters={filters}
        images={images}
        isListOpen={isImageListOpen}
        labels={labels}
        navigateTo={navigateTo}
        projectType={projectType}
        removeAnnotation={removeAnnotation}
        siblingIndex={siblingIndex}
        toggleList={toggleList}
        toggleSurvey={this.toggleSurveyQuestion}
        updateSiblingIndex={updateSiblingIndex}
      >
        <LabelingContainer
          key={activeImage._id}
          activeAnnotationIndex={activeAnnotationIndex}
          activePolylineIndex={activePolylineIndex}
          driveInfo={driveInfo}
          image={activeImage}
          isImageListOpen={isImageListOpen}
          labels={labels}
          polylines={polylines}
          projectType={projectType}
          siblingIndex={siblingIndex}
        />
        <LabelingSidebar
          activeAnnotationIndex={activeAnnotationIndex}
          activePolylineIndex={activePolylineIndex}
          groupInfo={imageGroups && imageGroups.get(activeImage.groupID)}
          image={activeImage}
          labels={labels}
          lastPolyWidth={lastPolyWidth}
          polylines={polylines}
          projectType={projectType}
          toggleSurvey={this.toggleSurveyQuestion}
          updateImage={updateImage}
        />
      </LabelingNavigation>
    );
  }
}

const mapStateToProps = ({
  annotations,
  filters,
  images,
  labeling,
  polylines,
  projects,
}) => ({
  filters,
  activeAnnotationIndex: annotations.activeIndex,
  activePolylineIndex: polylines.activeIndex,
  activeImage: images.active,
  activeImageIndex: images.activeIndex,
  activeVertexIndex: polylines.activeVertexIndex,
  imageChanged: images.hasChanges,
  imageGroups: images.groups,
  images: images.filteredList,
  isImageListOpen: labeling.isListOpen,
  lastPolyWidth: polylines.lastPolyWidth,
  polylines: polylines.active,
  polylinesChanged: polylines.hasChanges,
  project: projects.active,
  siblingIndex: labeling.siblingIndex,
});

const actions = {
  addAnnotation: imageActions.addImageAnnotation,
  reloadProject: projectActions.reloadProject,
  removeAnnotation: imageActions.deleteImageAnnotation,
  resetLabeling: labelingActions.reset,
  saveImage: imageActions.saveImage,
  savePolylines: polylineActions.saveChangedPolylines,
  toggleList: labelingActions.toggleImageList,
  updateImage: imageActions.updateImage,
  updateSiblingIndex: labelingActions.updateSiblingIndex,
};

export default connect(mapStateToProps, actions)(LabelingPage);
