import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import IconClose from './IconClose';
import { search as searchAction } from '../actions/dashboardActions';
import styles from './DashboardSearch.module.css';

const DELAY = 250;

function DashboardSearch({ onChangeSearch, value }) {
  const inputEl = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  const onClear = () => {
    setInputValue('');
    inputEl.current.focus();
  };

  const performSearch = debounce((query) => {
    onChangeSearch(query);
  }, DELAY, { maxWait: DELAY * 2 });

  useEffect(() => {
    performSearch(inputValue);
    return performSearch.cancel;
  }, [inputValue, performSearch]);

  return (
    <div className={styles.wrapper}>
      <input
        ref={inputEl}
        className={styles.input}
        onChange={(event) => setInputValue(event.currentTarget.value)}
        placeholder="Type to search projects..."
        type="search"
        value={inputValue}
      />
      {!!value && (
        <button className={styles.clearBtn} onClick={onClear}>
          <IconClose />
        </button>
      )}
    </div>
  );
}

const actions = {
  onChangeSearch: searchAction,
};

export default connect(null, actions)(DashboardSearch);
