import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LogoutButton.module.css';

export default function LogoutButton() {
  return (
    <Link to="/logout" className={styles.button}>
      Logout
    </Link>
  );
}
