import { batch } from 'react-redux';
import { polylineStore } from '../data';
import {
  CLEAR_POLYLINE_CHANGES,
  CREATE_POLYLINE,
  DELETE_POLYLINE,
  FIND_ACTIVE_POLYLINES,
  LOAD_POLYLINES,
  LOAD_POLYLINES_FAIL,
  LOAD_POLYLINES_SUCCESS,
  RESET_POLYLINES,
  SET_ACTIVE_POLYLINE,
  SET_CHANGED_POLYLINES,
  UPDATE_ACTIVE_POLYLINE,
  UPDATE_POLYLINE_BATCH,
  UPDATE_POLYLINE_WIDTH,
} from './actionTypes';
import { api, confirm } from '../utils';
import {
  OPERATIONS,
  appendVertex,
  createPolyline,
  deleteVertex,
  formatPolyline,
  getActivePolyline,
  getActivePolylines,
  insertVertex,
  joinPolylines,
  prependVertex,
  updatePolyline,
  updateVertex,
  deletePolyline,
  splitPolyline,
  splicePolyline,
} from '../models/polyline'

export function loadPolylines(projectId) {
  return (dispatch, getState) => {
    const { polylines } = getState();

    if (polylines.loading || polylines.projectId === projectId) return;

    dispatch({ type: LOAD_POLYLINES });

    return api.getProjectPolylines(projectId)
      .then((polys) => {
        polylineStore.addBulk(polys);

        dispatch({
          type: LOAD_POLYLINES_SUCCESS,
          payload: { projectId, count: polylineStore.count() },
        });
      })
      .catch((err) => {
        dispatch({ type: LOAD_POLYLINES_FAIL, payload: err, error: true });
      });
  };
}

export function findActivePolylines(worldReference) {
  const polylines = polylineStore.list();
  const activePolylines = getActivePolylines(polylines, worldReference);

  return {
    type: FIND_ACTIVE_POLYLINES,
    payload: activePolylines,
  };
}

export function addPolyline(position) {
  return (dispatch, getState) => {
    const { images, polylines, projects } = getState();
    const { LabelMap } = projects.active;
    const { worldReference } = images.active;
    const properties = {
      aoiWidth: polylines.lastPolyWidth,
      label: polylines.lastPolyLabel,
      worldReference,
    };
    const polyline = createPolyline(position, properties, LabelMap);
    const active = getActivePolyline(polyline, worldReference);

    polylineStore.add(polyline);
    dispatch({ type: CREATE_POLYLINE, payload: active });
  };
}

export function addVertexToPolyline(position, operation) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active, activeIndex } = polylines;
    const { worldReference } = images.active;
    const worldPoly = polylineStore.get(active[activeIndex]._id);
    let updated;

    if (operation === OPERATIONS.APPEND) {
      updated = appendVertex(worldPoly, position, worldReference);
    } else if (operation === OPERATIONS.PREPEND) {
      updated = prependVertex(worldPoly, position, worldReference);
    }

    if (updated == null) return;

    const nextActive = getActivePolyline(updated, worldReference);
    polylineStore.add(updated);
    dispatch({ type: UPDATE_ACTIVE_POLYLINE, payload: nextActive });
  };
}

export function insertPolylineVertex(position, vertexIndex) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active, activeIndex } = polylines;
    const { worldReference } = images.active;
    const poly = active[activeIndex];
    const worldPoly = polylineStore.get(poly._id);
    const worldVertexIndex = poly.activeVertices[vertexIndex];
    const updated = insertVertex(worldPoly, position, worldReference, worldVertexIndex);
    const nextActive = getActivePolyline(updated, worldReference);

    polylineStore.add(updated);
    dispatch({ type: UPDATE_ACTIVE_POLYLINE, payload: nextActive });
  };
}

export function mergePolylines(polylineIndex, sourceVertexIndex, targetVertexIndex) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active, activeIndex } = polylines;
    const left = active[activeIndex];
    const leftPoly = polylineStore.get(left._id);
    const leftVertexIndex = left.activeVertices[sourceVertexIndex];
    const right = active[polylineIndex];
    const rightPoly = polylineStore.get(right._id);
    const rightVertexIndex = right.activeVertices[targetVertexIndex];
    const joined = joinPolylines(
      leftPoly,
      leftVertexIndex,
      rightPoly,
      rightVertexIndex,
    );
    const updates = joined.filter((item) => item != null);

    polylineStore.addBulk(updates);

    batch(() => {
      dispatch({ type: SET_CHANGED_POLYLINES, payload: updates });
      dispatch({
        type: FIND_ACTIVE_POLYLINES,
        payload: getActivePolylines(polylineStore.list(), images.active.worldReference),
      });
      dispatch(setActivePolyline(null));
    })
  };
}

export function updatePolylineVertex(polylineIndex, vertexIndex, updates = {}) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active } = polylines;
    const { worldReference } = images.active;
    const currentPoly = active[polylineIndex];
    const worldPoly = polylineStore.get(currentPoly._id);
    const worldVertexIndex = currentPoly.activeVertices[vertexIndex];
    const updated = updateVertex(
      worldPoly,
      worldReference,
      worldVertexIndex,
      updates,
    );
    const nextActive = getActivePolyline(updated, worldReference);

    polylineStore.add(updated);
    dispatch({ type: UPDATE_ACTIVE_POLYLINE, payload: nextActive });
  };
}

export function updateActivePolyline(polylineIndex, updates = {}) {
  return (dispatch, getState) => {
    const { images, polylines, projects } = getState();
    const currentPoly = polylines.active[polylineIndex];
    const worldPoly = polylineStore.get(currentPoly._id);
    const updated = updatePolyline(
      worldPoly,
      updates,
      projects.active && projects.active.LabelMap,
    );
    const nextActive = getActivePolyline(updated, images.active.worldReference);

    polylineStore.add(updated);
    dispatch({ type: UPDATE_ACTIVE_POLYLINE, payload: nextActive });
  };
}

export function togglePolylineVisible(index) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const poly = polylines.active[index];
    const worldPoly = polylineStore.get(poly._id);
    const updated = updatePolyline(worldPoly, { visible: !poly.visible });
    const nextActive = getActivePolyline(updated, images.active.worldReference);

    polylineStore.add(updated);
    dispatch({ type: UPDATE_ACTIVE_POLYLINE, payload: nextActive });
  };
}

export function togglePolylinesVisible(indices) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active } = polylines
    const { worldReference } = images.active;

    const results = [];
    indices.forEach((index) => {
      const poly = active[index];
      const worldPoly = polylineStore.get(poly._id);
      const updated = updatePolyline(worldPoly, { visible: !poly.visible });
      const updatedActive = getActivePolyline(updated, worldReference);

      polylineStore.add(updated);

      if (updatedActive) {
        results.push(updatedActive);
      }
    });

    dispatch({
      type: UPDATE_POLYLINE_BATCH,
      payload: {
        indices,
        polylines: results
      },
    });
  };
}

export function updatePolylineWidth(width) {
  return { type: UPDATE_POLYLINE_WIDTH, payload: width };
}

export function deleteActivePolyline(polylineIndex) {
  return (dispatch, getState) => {
    if (!confirm('Are you sure you want to delete this polyline?')) return;

    const { polylines } = getState();
    const { active } = polylines;
    const poly = active[polylineIndex];
    const worldPoly = polylineStore.get(poly._id);
    const deleted = deletePolyline(worldPoly);

    polylineStore.add(deleted);

    batch(() => {
      dispatch({
        type: DELETE_POLYLINE,
        payload: {
          index: polylineIndex,
          polyline: deleted,
        },
      });

      if (active.length > 1) {
        let nextInd = polylineIndex;
        if (nextInd === (active.length-1)) nextInd = 0;
        dispatch(setActivePolyline(nextInd));
      } else {
        dispatch(setActivePolyline(null));
      }
    });
  };
}

export function deletePolylineVertex(polylineIndex, vertexIndex) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active } = polylines;
    const polyline = active[polylineIndex];
    const worldPoly = polylineStore.get(polyline._id);
    const worldVertexIndex = polyline.activeVertices[vertexIndex];
    const updated = deleteVertex(worldPoly, worldVertexIndex);

    polylineStore.add(updated);

    if (!updated.vertices.length) {
      return dispatch(deleteActivePolyline(polylineIndex));
    }

    const nextActive = getActivePolyline(updated, images.active.worldReference);
    if (!nextActive) {
      dispatch(setActivePolyline(null));
    } else {
      dispatch({
        type: UPDATE_ACTIVE_POLYLINE,
        payload: nextActive,
      });
    }
  };
}

export function splicePolylineVertices(polylineIndex, ltVertexIndex, rtVertexIndex) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active } = polylines;
    const polyline = active[polylineIndex];
    const worldPoly = polylineStore.get(polyline._id);
    const ltWorld = polyline.activeVertices[ltVertexIndex];
    const rtWorld = polyline.activeVertices[rtVertexIndex];
    const updated = splicePolyline(worldPoly, ltWorld, rtWorld);

    polylineStore.add(updated);

    const nextActive = getActivePolyline(updated, images.active.worldReference);
    if (!nextActive) {
      dispatch(setActivePolyline(null));
    } else {
      dispatch({
        type: UPDATE_ACTIVE_POLYLINE,
        payload: nextActive,
      });
    }
  };
}

export function splitPolylineAtVertex(polylineIndex, vertexIndex) {
  return (dispatch, getState) => {
    const { images, polylines } = getState();
    const { active } = polylines;
    const { worldReference } = images.active;
    const polyline = active[polylineIndex];
    const worldPoly = polylineStore.get(polyline._id);
    const worldVertexIndex = polyline.activeVertices[vertexIndex];
    const updates = splitPolyline(worldPoly, worldVertexIndex);

    polylineStore.addBulk(updates);

    batch(() => {
      const updatedActive = getActivePolyline(updates[0], worldReference);
      const created = getActivePolyline(updates[1], worldReference);
      dispatch({
        type: UPDATE_ACTIVE_POLYLINE,
        payload: updatedActive,
      });
      dispatch({
        type: CREATE_POLYLINE,
        payload: created,
      });
    });
  };
}

export function clearChanges() {
  return { type: CLEAR_POLYLINE_CHANGES };
}

export function saveChangedPolylines() {
  return (dispatch, getState) => {
    const { polylines } = getState();
    const { changedPolylines, projectId } = polylines;
    const promises = Object.keys(changedPolylines).map((id) => {
      const worldPoly = polylineStore.get(id);
      const body = formatPolyline(worldPoly);

      if (worldPoly.new) {
        return api.createProjectPolyline(projectId, [body])
          .then((response) => {
            polylineStore.remove(worldPoly);
            polylineStore.add(response);
            return response;
          });
      }

      if (worldPoly.edited || worldPoly.deleted) {
        return api.updateProjectPolyline(body)
          .then((polyResponse) => {
            polyResponse.edited = false;
            polylineStore.add(polyResponse);
            return polyResponse;
          });
      }

      return Promise.resolve(worldPoly);
    });

    Promise.all(promises)
      .then(() => {
        dispatch(clearChanges());
      });
  };
}

export function resetPolylines() {
  return { type: RESET_POLYLINES };
}

export function setActivePolyline(index) {
  return { type: SET_ACTIVE_POLYLINE, payload: index };
}
