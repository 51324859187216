export function formatDate(createdDate) {
  const month = createdDate.getMonth() + 1;
  const day = createdDate.getDate();
  const fullYear = createdDate.getFullYear();
  const year = String(fullYear).slice(-2);

  return `${month}/${day}/${year}`;
}

export function formatTime(createdDate) {
  const minute = createdDate.getMinutes();
  let ampm = 'am';
  let hour = createdDate.getHours();
  if (hour > 11) {
    hour = hour % 12;
    ampm = 'pm'
  }
  if (hour === 0) {
    hour = 12;
  }

  return `${hour}:${minute}${ampm}`;
}

export default { formatDate, formatTime };
