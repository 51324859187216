import produce from 'immer';

import {
  LOAD_JOBS,
  LOAD_JOBS_SUCCESS,
  LOAD_JOBS_FAIL,
  LOAD_DATA_SOURCES,
  LOAD_DATA_SOURCES_SUCCESS,
  LOAD_DATA_SOURCES_FAIL,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAIL,
  ADVANCE_JOB,
  ADVANCE_JOB_SUCCESS,
  ADVANCE_JOB_FAIL,
} from '../actions/actionTypes';

export const initialState = {
  loading: false,
  list: [],
  error: null,
};

function updateList(list, job) {
  const index = list.findIndex(item => item._id === job._id);

  if (index > -1) {
    list.splice(index, 1, job);
  } else {
    list.push(job);
  }

  return list;
}

export default produce(function jobsReducer(draft, action) {
  const { type, payload } = action;

  switch (type) {
    case ADVANCE_JOB:
    case CREATE_JOB:
    case LOAD_JOBS:
    case LOAD_DATA_SOURCES:
      draft.loading = true;
      break;

    case LOAD_JOBS_SUCCESS:
    case LOAD_DATA_SOURCES_SUCCESS: {
      draft.loading = false;
      draft.error = null;
      draft.list = payload;
      break;
    }

    case ADVANCE_JOB_FAIL:
    case CREATE_JOB_FAIL:
    case LOAD_JOBS_FAIL:
    case LOAD_DATA_SOURCES_FAIL: {
      draft.loading = false;
      draft.error = payload;
      break;
    }

    case CREATE_JOB_SUCCESS: {
      draft.list = updateList(draft.list, payload);
      draft.loading = false;
      break;
    }

    case ADVANCE_JOB_SUCCESS: {
      draft.list = updateList(draft.list, payload);
      draft.loading = false;
      break;
    }

    default:
      break;
  }
}, initialState);
