import React, { Component } from 'react';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer'
import { Link } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import * as filterActions from '../actions/filterActions';
import styles from './ImageList.module.css';

const ROW_HEIGHT = 40;
const WIDTH = 320;

class ImageListDropdown extends Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.listRef = React.createRef();

    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
    setTimeout(this.scrollToActive.bind(this), 50);
  }

  componentDidUpdate() {
    this.scrollToActive();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  scrollToActive() {
    const { activeIndex } = this.props;
    const list = this.listRef.current;

    if (activeIndex && list) {
      list.scrollToItem(activeIndex, 'center');
    }
  }

  handleChangeFilter({ target }) {
    switch (target.name) {
      case 'confidence': {
        this.props.changeConfidenceFilter(target.value);
        break;
      }

      case 'label': {
        this.props.changeLabelFilter(target.value);
        break;
      }

      default:
        break;
    }
  }

  handleClickOutside({ target }) {
    const { current: container } = this.containerRef;

    if (container && !container.contains(target)) {
      this.props.toggleList();
    }
  }

  rowRenderer({ index, style }) {
    const { activeIndex, images, toggleList } = this.props;
    const image = images[index];
    const isActive = activeIndex === index;
    let { displayname } = image;

    if (!displayname) {
      const { src } = image;
      const pathIndex = src.lastIndexOf('/');
      const paramsIndex = src.lastIndexOf('?');

      displayname = src.substring(pathIndex + 1);

      if (paramsIndex > -1) {
        displayname = displayname.substring(0, paramsIndex);
      }
    }

    return (
      <Link
        className={isActive ? styles.active : styles.item}
        onClick={toggleList}
        style={style}
        to={`/projects/${image.projId}/images/${image._id}`}
      >
        {displayname}
      </Link>
    );
  }

  render() {
    const { filters, images, labels, projectType } = this.props;
    const { length } = images;
    const { confidence, label } = filters;
    const confidenceValue = Number.parseInt(confidence * 100, 10);

    return (
      <div className={styles.listBody} ref={this.containerRef}>
        <div className={styles.filters}>
          <p><strong>Filter:</strong></p>
          <div className={styles.filtersRow}>
            <fieldset>
              <label htmlFor="filter-label">
                Class
              </label>
              <select
                id="filter-label"
                name="label"
                onChange={this.handleChangeFilter}
                value={label}
              >
                <option value="">none</option>
                {labels.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </select>
            </fieldset>
            {projectType === 'QC' && (
              <fieldset>
                <label htmlFor="confidence-filter">
                  Confidence ({confidenceValue})
                </label>
                <input
                  type="range"
                  name="confidence"
                  step="5"
                  onChange={this.handleChangeFilter}
                  onInput={this.handleChangeFilter}
                  value={confidenceValue}
                />
              </fieldset>
            )}
          </div>
          <p>{length} Images</p>
        </div>
        <div className={styles.listWrapper}>
          <AutoSizer>
            {({ height }) => (
              <List
                ref={this.listRef}
                height={height}
                itemCount={length}
                itemKey={(index) => images[index]._id}
                itemSize={ROW_HEIGHT}
                width={WIDTH}
              >
                {this.rowRenderer}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const actions = {
  changeConfidenceFilter: filterActions.changeConfidenceFilter,
  changeLabelFilter: filterActions.changeLabelFilter,
};

export default connect(null, actions)(ImageListDropdown);
