import {
  LOAD_JOBS,
  LOAD_JOBS_SUCCESS,
  LOAD_JOBS_FAIL,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAIL,
  ADVANCE_JOB,
  ADVANCE_JOB_SUCCESS,
  ADVANCE_JOB_FAIL,
  LOAD_DATA_SOURCES,
  LOAD_DATA_SOURCES_SUCCESS,
  LOAD_DATA_SOURCES_FAIL,
} from './actionTypes';
import { api, auth } from '../utils';
import { reloadProject } from './projectActions';
import projectStore from '../data/projectStore';
import dataSourceStore from '../data/dataSourceStore';

export function loadJobs() {
  return (dispatch) => {
    if (!auth.isAdmin()) return;

    dispatch({ type: LOAD_JOBS });

    return api.getProcessingJobs()
      .then(jobs => dispatch({ type: LOAD_JOBS_SUCCESS, payload: jobs }))
      .catch((err) => {
        dispatch({ type: LOAD_JOBS_FAIL, payload: err, error: true });
      });
  };
}

const isExtractionJob = (params) => {
  return params.beacon_drive_id != null;
}

export function createJob(params) {
  return (dispatch) => {
    if (!auth.isAdmin()) return;

    dispatch({ type: CREATE_JOB });

    /// NB: Extraction jobs should be created in state 1 (Ingest) then advanced.  New/Ingest jobs are auto-started.  TODO: clean this up.
    params.processing_state = isExtractionJob(params) ? 1 : 0;

    return api.createProcessingJob(params)
      .then(job => {
        // Advance if necessary.
        if (isExtractionJob(params)) return api.advanceProcessingJob(job._id);
        else return Promise.resolve(job);
      })
      .then(job=>dispatch({ type: CREATE_JOB_SUCCESS, payload: job }))
      .catch((err) => {
        dispatch({ type: CREATE_JOB_FAIL, payload: err, error: true });
      });
  };
}

export function advanceJob(id) {
  return (dispatch) => {
    if (!auth.isAdmin()) return;

    dispatch({ type: ADVANCE_JOB });

    return api.advanceProcessingJob(id)
      .then(job => dispatch({ type: ADVANCE_JOB_SUCCESS, payload: job }))
      .catch((err) => {
        dispatch({ type: ADVANCE_JOB_FAIL, payload: err, error: true });
      });
  };
}

export function handleWorkerMessage(message) {
  return (dispatch) => {
    if (message.type === LOAD_JOBS_SUCCESS) {
      message.payload.forEach((job) => {
        if (job.qc_project_id) {
          const proj = projectStore.get(job.qc_project_id);
          if (!proj) {
            reloadProject(job.qc_project_id)(dispatch);
          }
        }
      });
    }
    else if (message.type === LOAD_DATA_SOURCES_SUCCESS) {
      dataSourceStore.addBulk(message.payload);
    }

    dispatch(message);
  };
}

export function loadDataSources() {
  return (dispatch) => {
    console.log('dispatched loadDataSources');
    if (!auth.isAdmin()) {
      return;
    }

    dispatch({ type: LOAD_DATA_SOURCES });

    return api.getUserDataSources()
      .then(sources => {
        dataSourceStore.addBulk(sources);
        //dispatch({ type: LOAD_DATA_SOURCES_SUCCESS, payload: sources })
      })
      .catch((err) => {
        dispatch({ type: LOAD_DATA_SOURCES_FAIL, payload: err, error: true });
      });
  };
}
