import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DashboardPage from '../pages/Dashboard';
import LoadProjects from '../components/LoadProjects';
import { reset as dashboardAction } from '../actions/dashboardActions';
import {
  advanceJob as advanceJobAction,
  loadJobs as loadJobsAction,
  handleWorkerMessage,
} from '../actions/jobActions';
import { exportProject as projectAction } from '../actions/projectActions';
import { formatDashboardData } from '../models/dashboard';
import { auth } from '../utils';
import Worker from '../workers/dashboard.worker';

function Dashboard({
  advanceJob,
  exportProject,
  handleMessage,
  listItems,
  loadJobs,
  reset,
  search,
  sort,
}) {
  const [jobsLoaded, setJobsLoaded] = useState(false);

  useEffect(() => {
    if (!jobsLoaded) {
      loadJobs();
      setJobsLoaded(true);
    }
  }, [jobsLoaded, loadJobs, setJobsLoaded]);

  useEffect(() => {
    const worker = new Worker();

    worker.postMessage({ token: auth.getToken() });

    worker.addEventListener('message', (event) => {
      handleMessage(event.data);
    });

    return () => worker.terminate();
  }, [handleMessage]);

  return (
    <LoadProjects>
      <DashboardPage
        items={listItems}
        onClick={reset}
        onClickExport={exportProject}
        onClickPublish={advanceJob}
        search={search}
        sort={sort}
      />
    </LoadProjects>
  )
}

const mapStateToProps = ({ dashboard, jobs, projects }) => {
  const { search, sort } = dashboard;
  const listItems = formatDashboardData({
    search,
    sort,
    jobs: jobs.list,
    projects: projects.list,
  });

  return { listItems, search, sort };
};

const actions = {
  advanceJob: advanceJobAction,
  exportProject: projectAction,
  handleMessage: handleWorkerMessage,
  loadJobs: loadJobsAction,
  reset: dashboardAction,
};

export default connect(mapStateToProps, actions)(Dashboard);
