import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

export default function NotFound() {
  return (
    <div className={styles.message}>
      <p>
        Sorry, the page you are looking for connot be found.
      </p>
      <p>
        <Link to="/">Return home</Link>
      </p>
    </div>
  );
}
