import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as polylineActions from '../actions/polylineActions';
import PolylineSegments from './PolylineSegments';
import PolylineVertex from './PolylineVertex';
import { OPERATIONS, isDelete, isSplit } from '../models/polyline';

// Prebuilt visibility 'modes':
// ALL: show all polylines
// TYPE_BASED: cycle through type signature (type/subtype) map.
// NONE: hide all polylines
const VisibilityModes = Object.freeze({
  ALL: 0,
  TYPE_BASED: 1,
  NONE: 2,
});

function polylineShouldBeVisible(polyline, visibilityMode, index, types) {
  const { label, style, visible } = polyline;

  if (visibilityMode === VisibilityModes.NONE) {
    return visible;
  }

  if (visibilityMode === VisibilityModes.TYPE_BASED) {
    const typeIndex = types.findIndex(({ type, subtype }) => (
      type === label
      && subtype === style
    ));
    const shouldBeVisible = index === typeIndex;

    return visible !== shouldBeVisible;
  }

  return !visible;
}

function typeSignatures(polylines) {
  const typeMap = new Map();
  polylines.forEach(({ label, style }) => {
    const key = `${label}/${style}`;
    if (!typeMap.has(key)) {
      typeMap.set(key, { type: label, subtype: style });
    }
  });

  return Array.from(typeMap.values()).sort((left, right) => {
    if (left.type === right.type) return left.subtype - right.subtype;
    return left.type - right.type;
  });
}

class PolylineOverlays extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibilityIndex: -1,
      visibilityMode: VisibilityModes.ALL,
    };
    this.hover = null;
    this.ctrlDown = false;
    this.shiftDown = false;
    this.typeSignatures = typeSignatures(props.polylines);

    this.applyVisibilityFilter = this.applyVisibilityFilter.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate({ polylines: prevPolylines }) {
    const { polylines } = this.props;
    if (prevPolylines !== polylines) {
      this.typeSignatures = typeSignatures(polylines);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  // event is a React SyntheticEvent
  handleClick(polylineIndex, vertexIndex, isEndpoint, event) {
    const { setPolylineOperation } = this.props;

    if (this.ctrlDown) {
      event.preventDefault();
      event.stopPropagation();

      if (this.shiftDown) {
        const { onChangeEnd, splitPolyline } = this.props;

        this.hover = null;
        splitPolyline(polylineIndex, vertexIndex);
        this.clearOperation();
        onChangeEnd();
        return;
      }

      let operation = OPERATIONS.INSERT;
      if (isEndpoint) {
        if (vertexIndex === 0) {
          operation = OPERATIONS.PREPEND;
        } else {
          operation = OPERATIONS.APPEND;
        }
      }

      setPolylineOperation({
        activeVertexIndex: vertexIndex,
        polylineOperation: operation,
      });
      return;
    }

    if (this.shiftDown) {
      const { deleteVertex, onChangeEnd } = this.props;

      this.hover = null;
      deleteVertex(polylineIndex, vertexIndex);
      this.clearOperation();
      onChangeEnd();
      return;
    }
  }

  handleDragStart(polylineIndex, vertexIndex, event) {
    const { onChangeStart, operation, setActivePolyline } = this.props;

    onChangeStart();

    if (event.ctrlKey && !isDelete(operation) && !isSplit(operation)) {
      const { activeIndex, activeVertexIndex, joinPolylines, splicePolyline, onChangeEnd } = this.props;

      event.preventDefault();
      event.stopPropagation();

      if (activeIndex != null && activeIndex !== polylineIndex) {
        joinPolylines(polylineIndex, activeVertexIndex, vertexIndex);
        this.clearOperation();
        onChangeEnd();
      }
      else if (activeIndex != null && activeIndex===polylineIndex && activeVertexIndex !== null) {
        splicePolyline(polylineIndex, activeVertexIndex, vertexIndex);
        this.clearOperation();
        onChangeEnd();
      }
    }

    setActivePolyline(polylineIndex);
  }

  handleDrag(polylineIndex, vertexIndex, vertex, isEnd = false) {
    const { updatePolylineVertex } = this.props;
    const position = this.computeVertex(vertex);

    updatePolylineVertex(polylineIndex, vertexIndex, position);

    if (isEnd) {
      const { onChangeEnd } = this.props;
      onChangeEnd();
    }
  }

  // event is a React SyntheticEvent
  handleHover(polylineIndex, vertexIndex, mouseLeave, event) {
    this.hover = mouseLeave ? null : { polylineIndex, vertexIndex };

    this.checkOperation();
    this.forceUpdate();
  }

  // event is a KeyboardEvent
  handleKeyDown({ key }) {
    if (key === 'Control') {
      this.ctrlDown = true;
    }

    if (key === 'Shift') {
      this.shiftDown = true;
    }

    this.checkOperation();
  }

  // event is a KeyboardEvent
  handleKeyUp({ key }) {
    if (key === 'Control') {
      this.ctrlDown = false;
      this.clearOperation();
    }

    if (key === 'Shift') {
      this.shiftDown = false;
      this.clearOperation();
    }

    this.checkOperation();

    if (key !== 'v') return;

    // use callback form to avoid async state update bugs
    // https://reactjs.org/docs/react-component.html#setstate
    this.setState((state) => {
      const { visibilityIndex, visibilityMode } = state;
      let nextIndex = visibilityIndex + 1;

      if (visibilityMode === VisibilityModes.TYPE_BASED
        && nextIndex < this.typeSignatures.length) {
        return { visibilityIndex: nextIndex };
      }

      let nextMode = visibilityMode + 1;
      if (nextMode > VisibilityModes.NONE) {
        nextMode = VisibilityModes.ALL;
      }

      nextIndex = nextMode === VisibilityModes.TYPE_BASED ? 0 : -1;

      return {
        visibilityIndex: nextIndex,
        visibilityMode: nextMode,
      };
    }, this.applyVisibilityFilter);
  }

  applyVisibilityFilter() {
    const { polylines, toggleGroupVisibility } = this.props;
    const { visibilityIndex, visibilityMode } = this.state;
    const toToggle = polylines.reduce((result, polyline, pIndex) => {
      if (polyline.deleted) return result;

      if (polylineShouldBeVisible(polyline, visibilityMode, visibilityIndex, this.typeSignatures)) {
        result.push(pIndex);
      }

      return result;
    }, []);

    toggleGroupVisibility(toToggle);
  }

  computeVertex({ x, y }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.props;

    return {
      x: (x - imageX) / imageWidth,
      y: (y - imageY) / imageHeight,
    };
  }

  getPosition({ x, y }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.props;

    return {
      x: imageX + imageWidth * x,
      y: imageY + imageHeight * y,
    };
  }

  clearOperation() {
    const { setPolylineOperation } = this.props;
    this.hover = null;
    setPolylineOperation({
      activeVertexIndex: null,
      polylineOperation: null,
    });
  }

  checkOperation() {
    const { setPolylineOperation, operation } = this.props;

    if (
      (this.hover == null && (isDelete(operation) || isSplit(operation)))
      || (isDelete(operation) && !this.shiftDown)
      || (isSplit(operation) && !this.shiftDown && !this.ctrlDown)
    ) {
      this.clearOperation();
      return;
    }

    if (this.hover != null) {
      if (this.shiftDown) {
        const { setActivePolyline } = this.props;
        let operation = OPERATIONS.DELETE;

        if (this.ctrlDown) {
          operation = OPERATIONS.SPLIT;
        }

        setActivePolyline(this.hover.polylineIndex);
        setPolylineOperation({
          activeVertexIndex: this.hover.vertexIndex,
          polylineOperation: operation,
        });
      }
    }
  }

  renderPolyline(activeIndex, polyline, polylineIndex) {
    if (polyline == null || polyline.deleted || !polyline.visible) return null;

    const { vertices } = polyline;
    const lastIndex = vertices.length - 1;
    const isActive = activeIndex === polylineIndex;
    const isOutsideImage = (vertex) => (
      vertex.x < 0 || vertex.x > 1
      || vertex.y < 0 || vertex.y > 1
    );
    const isEndpoint = (vertex, index) => (
      (index === 0 || index === lastIndex)
      && vertex.x >= 0 && vertex.x <= 1
      && vertex.y >= 0 && vertex.y <= 1
    );

    return vertices.map((vertex, index) => {
      const endpoint = isEndpoint(vertex, index);

      return (
        <PolylineVertex
          key={vertex._id}
          isActive={isActive}
          isDisabled={isOutsideImage(vertex)}
          isEndpoint={endpoint}
          onClick={this.handleClick.bind(this, polylineIndex, index, endpoint)}
          onDrag={this.handleDrag.bind(this, polylineIndex, index)}
          onDragStart={this.handleDragStart.bind(this, polylineIndex, index)}
          onMouseEnter={this.handleHover.bind(this, polylineIndex, index, false)}
          onMouseLeave={this.handleHover.bind(this, polylineIndex, index, true)}
          position={this.getPosition(vertex)}
        />
      );
    });
  }

  render() {
    const { polylines } = this.props;

    if (!polylines || !polylines.length) return null;

    const {
      activeIndex,
      activeVertexIndex,
      customCanvasEvents,
      operation,
      proposedVertex,
    } = this.props;

    // If we have a hover point, pass in a secondary vertex for splice preview.
    let splicePreviewIndex = activeVertexIndex!==null && this.ctrlDown && this.hover && this.hover.vertexIndex!==activeVertexIndex && this.hover.polylineIndex===activeIndex ? this.hover.vertexIndex : null;
    return (
      <>
        <PolylineSegments
          activeIndex={activeIndex}
          activeVertexIndex={activeVertexIndex}
          nextVertexIndex={splicePreviewIndex}
          customCanvasEvents={customCanvasEvents}
          getVertexPosition={this.getPosition}
          operation={operation}
          polylines={polylines}
          proposedVertex={proposedVertex}
        />
        {polylines.map(this.renderPolyline.bind(this, activeIndex))}
      </>
    );
  }
}

const actions = {
  deleteVertex: polylineActions.deletePolylineVertex,
  insertVertex: polylineActions.insertPolylineVertex,
  joinPolylines: polylineActions.mergePolylines,
  splicePolyline: polylineActions.splicePolylineVertices,
  setActivePolyline: polylineActions.setActivePolyline,
  splitPolyline: polylineActions.splitPolylineAtVertex,
  updatePolylineVertex: polylineActions.updatePolylineVertex,
  toggleGroupVisibility: polylineActions.togglePolylinesVisible,
};

export default connect(null, actions)(PolylineOverlays);
