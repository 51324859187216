import React from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import UserInfo from './UserInfo';
import styles from './SessionInfo.module.css';
import { getApiUrl } from '../utils/api';

export default function SessionInfo() {
  const server = getApiUrl(window).replace(/^https?:\/\//, '');
  return (
    <div className={styles.container}>
      <UserInfo />
      <div>
        <LogoutButton />
        <a href="/help/" className={styles.helpBtn}>
          Help Docs
        </a>
        <div className={styles.linksRow}>
          <Link to="/about.pdf" target="_blank">
            About
          </Link>
          <Link to="/terms-of-service.pdf" target="_blank">
            Terms of Service
          </Link>
        </div>
        <div className={styles.metadata}>
          <small>Client: {process.env.REACT_APP_VERSION}</small>
          <small>Server: {server}</small>
          <small>Hash: {process.env.REACT_APP_HASH}</small>
        </div>
        <p className={styles.copyright}>
          <small>
            &copy; {new Date().getFullYear()} &mdash;
            Allvision IO &mdash;
            All Rights Reserved
          </small>
        </p>
      </div>
    </div>
  );
}

