import { createId, logger } from '../utils';

export const OPERATIONS = Object.freeze({
  PREPEND: 0,
  APPEND: 1,
  INSERT: 2,
  DELETE: 3,
  SPLIT: 4,
  MOVE: 5,
});

export const isDelete = (operation) => operation === OPERATIONS.DELETE;

export const isSplit = (operation) => operation === OPERATIONS.SPLIT;

function createVertex(x, y, worldReference) {
  const { height, width, bottom, left } = getWorldDimensions(worldReference);

  return {
    _id: createId(),
    new: true,
    x: left + (x * width),
    y: bottom + ((1 - y) * height),
  };
}

function findLabel(label, labels) {
  return labels.find(({ id }) => id === label);
}

function getActiveVertices(worldReference, { vertices }) {
  let wasInImage = false;
  let lastIndex = null;

  return vertices.reduce((active, vertex, index) => {
    if (isInBounds(worldReference, vertex)) {
      if (!wasInImage && lastIndex != null) {
        active.push(lastIndex);
      }
      active.push(index);
      wasInImage = true;
    } else if (wasInImage) {
      active.push(index);
      wasInImage = false;
    }

    lastIndex = index;

    return active;
  }, []);
}

function getWorldDimensions({ top, right, bottom, left }) {
  return {
    height: top - bottom,
    width: right - left,
    bottom,
    left,
  };
}

function isInBounds(world, { x, y }) {
  return (
    world.bottom <= y && world.top >= y
    && world.left <= x && world.right >= x
  );
}

function isInImage(worldReference, polyline) {
  if (!polyline) return false;
  return polyline.vertices.some((vertex) => isInBounds(worldReference, vertex));
}

export function createPolyline(position, properties = {}, labels = []) {
  const { aoiWidth, worldReference, label = 0 } = properties;
  const vertex = createVertex(position.x, position.y, worldReference);
  const polyline = {
    aoiWidth,
    label,
    _id: createId(),
    new: true,
    vertices: [vertex],
  };

  const labelObj = findLabel(label, labels);
  if (labelObj && labelObj.label === 'Road Paint') {
    polyline.style = 'Solid';
  }

  return polyline;
}

export function formatPolyline(poly) {
  const formatted = {
    aoiWidth: poly.aoiWidth,
    deleted: poly.deleted,
    edited: poly.edited,
    label: poly.label,
    vertices: poly.vertices.map((vertex) => {
      if (vertex._id.startsWith('new')) {
        return { x: vertex.x, y: vertex.y, z: vertex.z };
      }
      return vertex;
    }),
    style: poly.style,
  };
  if (!poly.new || !poly._id.startsWith('new')) {
    formatted._id = poly._id;
  }
  return formatted;
}

export function deleteVertex(polyline, vertexIndex) {
  const vertices = polyline.vertices.filter((v, index) => index !== vertexIndex);

  return updatePolyline(polyline, { vertices });
}

export function splitPolyline(polyline, vertexIndex) {
  const { vertices } = polyline;
  const splitVertices = vertices.slice(0, vertexIndex);
  const newVertices = vertices.slice(vertexIndex);
  const updated = updatePolyline(polyline, { vertices: splitVertices });
  const newPoly = {
    _id: createId(),
    aoiWidth: updated.aoiWidth,
    aoiWidthWorld: updated.aoiWidthWorld,
    deleted: false,
    edited: false,
    label: updated.label,
    new: true,
    projId: updated.projId,
    style: updated.style,
    vertices: newVertices,
  };

  return [updated, newPoly];
}

export function getActivePolyline(polyline, worldReference) {
  if (!isInImage(worldReference, polyline)) return null;

  const {
    aoiWidth: aoiWidthWorld,
    vertices: polylineVertices,
  } = polyline;
  const { bottom, left, height, width } = getWorldDimensions(worldReference);
  const activeVertices = getActiveVertices(worldReference, polyline);
  const vertices = activeVertices.map((index) => {
    const vertex = polylineVertices[index];

    return {
      ...vertex,
      x: (vertex.x - left) / width,
      y: 1 - ((vertex.y - bottom) / height),
    };
  });
  const aoiWidth = aoiWidthWorld == null ? null : {
    x: aoiWidthWorld / width,
    y: aoiWidthWorld / height,
  };

  return {
    ...polyline,
    activeVertices,
    aoiWidth,
    aoiWidthWorld,
    vertices,
    visible: polyline.visible == null || polyline.visible,
  };
}

export function getActivePolylines(polylines, worldReference) {
  return polylines.reduce((lines, polyline, index) => {
    if (polyline.deleted) return lines;

    const active = getActivePolyline(polyline, worldReference);

    if (active) {
      lines.push(active);
    }

    return lines;
  }, []);
}

export function appendVertex(polyline, position, worldReference) {
  const vertex = createVertex(position.x, position.y, worldReference);
  const vertices = [...polyline.vertices, vertex];

  return updatePolyline(polyline, { vertices });
}

export function insertVertex(polyline, position, worldReference, vertexIndex) {
  const sliceIndex = vertexIndex + 1;
  const vertex = createVertex(position.x, position.y, worldReference);
  const vertices = [
    ...polyline.vertices.slice(0, sliceIndex),
    vertex,
    ...polyline.vertices.slice(sliceIndex),
  ]

  return updatePolyline(polyline, { vertices });
}

export function prependVertex(polyline, position, worldReference) {
  const vertex = createVertex(position.x, position.y, worldReference);
  const vertices = [vertex, ...polyline.vertices];

  return updatePolyline(polyline, { vertices });
}

export function updateVertex(polyline, worldReference, vertexIndex, updates) {
  const { bottom, left, height, width } = getWorldDimensions(worldReference);
  const updatedVertex = {
    ...polyline.vertices[vertexIndex],
    x: left + updates.x * width,
    y: bottom + (1 - updates.y) * height,
  };

  return {
    ...polyline,
    edited: true,
    vertices: [
      ...polyline.vertices.slice(0, vertexIndex),
      updatedVertex,
      ...polyline.vertices.slice(vertexIndex + 1),
    ],
  };
}

export function updatePolyline(polyline, updates, labels = []) {
  return Object.entries(updates).reduce((updated, [key, val]) => {
    if (key === 'aoiWidth' && (Number.isNaN(val) || val < 0 || val > 24)) {
      return updated;
    }

    if (key === 'label') {
      const labelObj = findLabel(val, labels);

      if (labelObj && labelObj.label === 'Road Paint' && !updated.style) {
        updated.style = 'Solid';
      }
    }

    updated[key] = val;

    return updated;
  }, { ...polyline, edited: true });
}

export function deletePolyline(poly) {
  return updatePolyline(poly, { deleted: true });
}

export function joinPolylines(leftPoly, leftVertexIndex, rightPoly, rightVertexIndex) {
  if (leftPoly.type !== rightPoly.type) return [leftPoly, rightPoly];

  let updatedLeft;
  let updatedRight;

  if (leftVertexIndex === 0 && rightVertexIndex === rightPoly.vertices.length - 1) {
    updatedLeft = updatePolyline(leftPoly, {
      vertices: rightPoly.vertices.concat(leftPoly.vertices),
    });
    updatedRight = deletePolyline(rightPoly);
  } else if (rightVertexIndex === 0 && leftVertexIndex === leftPoly.vertices.length - 1) {
    updatedLeft = updatePolyline(leftPoly, {
      vertices: leftPoly.vertices.concat(rightPoly.vertices),
    });
    updatedRight = deletePolyline(rightPoly);
  } else if (rightVertexIndex === 0 && leftVertexIndex === 0) {     // Start to start: reverse left
    updatedLeft = updatePolyline(leftPoly, {
      vertices: leftPoly.vertices.reverse().concat(rightPoly.vertices),
    });
    updatedRight = deletePolyline(rightPoly);
  } else if (rightVertexIndex === rightPoly.vertices.length-1 && leftVertexIndex === leftPoly.vertices.length-1) {    // end to end: reverse right
    updatedLeft = updatePolyline(leftPoly, {
      vertices: leftPoly.vertices.concat(rightPoly.vertices.reverse()),
    });
    updatedRight = deletePolyline(rightPoly);
  } else {
    logger.warn('Illegal polyline join operation. Ignoring!');
  }

  return [updatedLeft, updatedRight];
}

export function splicePolyline(polyline, leftVertexIndex, rightVertexIndex) {
  let from = leftVertexIndex;
  let to = rightVertexIndex;
  if (from === to) return polyline;
  if (from > to) {
    const tmp = from;
    from = to;
    to = tmp;
  }
  if (1===(to-from)) return polyline;

  console.log(`splice ${ from }, ${ to }`)

  return {
    ...polyline,
    edited: true,
    vertices: [
      ...polyline.vertices.slice(0, from+1),
      ...polyline.vertices.splice(to),
    ]
  };
}
