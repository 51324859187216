export function logout() {
  const { pathname } = window.location;

  if (pathname !== '/login') {
    window.history.replaceState({ from: { pathname } }, null, '/login');
  }
}

export function redirectAuthenticatedUserToDashboard() {
  if (window.location.pathname === '/login') {
    window.history.replaceState(null, null, '/dashboard');
  }
}

export default {
  logout,
  redirectAuthenticatedUserToDashboard,
};
