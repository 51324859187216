import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import CreateJob from './CreateJob';
import ListItem from './DashboardListItem';
import Search from './DashboardSearch';
import Sort from './DashboardSort';
import { auth } from '../utils';
import styles from './DashboardList.module.css';

export default function DashboardList({
  items,
  onClick,
  onClickExport,
  onClickPublish,
  search,
  sort,
}) {
  const itemCount = items.length;

  const renderItem = ({ index, style }) => (
    <div style={style}>
      {index === itemCount ? <div /> : (
        <ListItem
          data={items[index]}
          onClick={onClick}
          onClickExport={onClickExport}
          onClickPublish={onClickPublish}
        />
      )}
    </div>
  );

  const renderList = ({ height, width }) => {
    if (search && !itemCount) {
      return (
        <div className={styles.emptyState}>
          <p>No Results Found</p>
        </div>
      );
    }

    return (
      <List
        className={styles.list}
        height={height}
        itemCount={itemCount + 1}
        itemKey={(index) => items[index] ? items[index].id : index}
        itemSize={86}
        width={width}
      >
        {renderItem}
      </List>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchRow}>
        <Search value={search} />
        <div className={styles.sortWrapper}>
          <Sort value={sort} />
          {auth.isAdmin() && <CreateJob />}
        </div>
      </div>
      <div className={styles.listContainer}>
        <AutoSizer>
          {renderList}
        </AutoSizer>
      </div>
    </div>
  );
}
