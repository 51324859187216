import React from 'react';
import AnnotationOverlays from './AnnotationOverlays';
import PolylineOverlays from './PolylineOverlays';
import styles from './ImageOverlays.module.css';

export default function ImageOverlays({
  errorMessage,
  hasPolylines,
  image,
  imageHeight,
  imageWidth,
  imageX,
  imageY,
  isLoaded,
  onChangeEnd,
  onChangeStart,
  projectType,
  siblingIndex,
  ...props
}) {
  if (errorMessage != null) {
    return (
      <p className={styles.errorMessage}>
        {errorMessage}
      </p>
    );
  }

  if (siblingIndex != null) {
    return (
      <div className={styles.badge}>
        Rotation {siblingIndex + 1} of {image.siblingImages.length}
      </div>
    );
  }

  if (!isLoaded || (!imageHeight && !imageWidth)) return null;

  if (hasPolylines) {
    return (
      <PolylineOverlays
        activeIndex={props.activePolylineIndex}
        activeVertexIndex={props.activeVertexIndex}
        customCanvasEvents={props.customCanvasEvents}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        imageX={imageX}
        imageY={imageY}
        setPolylineOperation={props.setPolylineOperation}
        onChangeEnd={onChangeEnd}
        onChangeStart={onChangeStart}
        operation={props.polylineOperation}
        polylines={props.polylines}
        proposedVertex={props.proposedVertex}
      />
    );
  }

  if (projectType === 'Labeling') {
    return (
      <AnnotationOverlays
        activeIndex={props.activeAnnotationIndex}
        annotations={image.annotations}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        imageX={imageX}
        imageY={imageY}
        labels={props.labels}
        onChangeEnd={onChangeEnd}
        onChangeStart={onChangeStart}
      />
    );
  }

  return null;
}
