import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Labeling from './Labeling';
import LoadImages from '../components/LoadImages';
import Loading from '../components/Loading';
import NotFound from '../pages/NotFound';

class ImagesRoute extends React.Component {
  constructor(props) {
    super(props);

    this.renderRoute = this.renderRoute.bind(this);
  }

  renderRoute() {
    const { isLoading, projectLoading } = this.props;

    if (isLoading || projectLoading) return <Loading />;

    const { images } = this.props;
    const { length } = images;
    let index = 0;
    let activeIndex = index;
    let focused = null;

    for (; index < length; index += 1) {
      const image = images[index];

      if (!image.reviewed) {
        activeIndex = index;
        break;
      }
    }

    focused = images[activeIndex];

    if (focused) {
      const { match } = this.props;
      return <Redirect to={`${match.url}/${focused._id}`} />;
    }

    return <NotFound />;
  }

  render() {
    const { match } = this.props;
    const { params, path } = match;

    return (
      <LoadImages projectId={params.projectId}>
        <Switch>
          <Route path={`${path}/:imageId`} component={Labeling} />
          <Route path={path} render={this.renderRoute} />
        </Switch>
      </LoadImages>
    );
  }
}

const mapStateToProps = ({ images, projects }) => ({
  isLoading: images.loading,
  images: images.list,
  projectLoading: projects.loading,
});

export default connect(mapStateToProps)(ImagesRoute);
