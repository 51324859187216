import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import { auth } from '../utils';

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.handleClickLogout = this.handleClickLogout.bind(this);
  }

  handleClickLogout() {
    const { history } = this.props;

    auth.unsetCurrentUser();
    history.push('/login');
  }

  render() {
    return (
      <nav className={styles.navbar}>
        <Link to="/">
          <img
            className={styles.browse}
            src={`${process.env.PUBLIC_URL}/resources/logo.png`}
            alt="Allvision LABS"
          />
        </Link>
      </nav>
    );
  }
}
