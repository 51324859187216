export function formatDashboardData({
  jobs,
  projects,
  search,
  sort,
}) {
  const findProject = ({ qc_project_id: projectId }) =>
    projects.find((project) => projectId === project._id);

  const rowCache = new Map();
  let items = [];
  let oldestJobMap = new Map();

  jobs.forEach((job) => {
    const project = findProject(job);
    const { processing_state: processingState } = job;
    const isProcessing = processingState !== 3 && processingState !== 6;
    const isComplete = processingState===6;
    let errorMessage = null;
    let isReviewComplete = false;
    let { projectName } = job;

    if (job.error_state) {
      errorMessage = job.error_state.message;
    }

    if (project) {
      if (project.projectType === 'Labeling') return;

      rowCache.set(project._id, true);

      isReviewComplete = project.reviewedCt === project.imageCt;
    }

    // Assign driveId for organization.
    const driveId = job.beacon_drive_id || '(Ingestion Jobs)';
    const jobDate = new Date(job.createdAt);

    let driveEntry = oldestJobMap.get(driveId);
    if (!driveEntry || driveEntry > jobDate) oldestJobMap.set(driveId, jobDate);

    items.push({
      errorMessage,
      isProcessing,
      isReviewComplete,
      isComplete,
      project,
      createdAt: jobDate,
      id: job._id,
      progress: job.progress,
      projectName: projectName || 'Unnamed Project',
      projectType: 'QC',
      driveId,
    });
  });

  projects.forEach((project) => {
    if (rowCache.has(project._id)) return;

    items.push({
      project,
      createdAt: new Date(project.createdAt),
      id: project._id,
      isReviewComplete: project.reviewedCt === project.imageCt,
      projectName: project.projectName,
      projectType: project.projectType,
      driveId: '(Labeling Projects)',
    });
  });

  if (search) {
    const pattern = new RegExp(search, 'i');
    items = items.filter((item) => pattern.test(item.projectName) || pattern.test(item.driveId));
  }

  // Sort by Drive, then by selected criteria.
  items.sort((left, right) => {
    if (left.driveId !== right.driveId) {
      if (sort==='createdAt') {
        return oldestJobMap.get(left.driveId) - oldestJobMap.get(right.driveId);
      }
      else return oldestJobMap.get(right.driveId) - oldestJobMap.get(left.driveId);
    }

    switch (sort) {
      case 'createdAt':
        return left.createdAt - right.createdAt;
      case 'projectName':
        return left.projectName.localeCompare(right.projectName);
      case '-projectName':
        return right.projectName.localeCompare(left.projectName);
      default:
        return right.createdAt - left.createdAt;
    }
  });

  // Insert separator items between drives.  This allows the rendering code to be simpler list-style.
  let last = null;
  let out = [];
  items.forEach(i=>{
    if (i.driveId && i.driveId !== last) {
      out.push({
        errorMessage: '',
        isProcessing:false,
        isReviewComplete:false,
        isComplete:false,
        project: '',
        createdAt: i.createdAt,
        id: i.driveId,
        progress: 0,
        projectName: null,
        projectType: null,
        driveId: i.driveId,
      });
      last = i.driveId;
    }
    out.push(i);
  });

  return out;
}

export function getProgress(progress, project) {
  if (project && project.imageCt > 0) {
    return `${(project.reviewedCt / project.imageCt) * 100}%`;
  }

  if (progress > 0) {
    if (progress < 1) {
      return `${progress * 100}%`;
    }
    return `${progress}%`;
  }

  return 0;
}
