import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as annotationActions from '../actions/annotationActions';
import * as imageActions from '../actions/imageActions';
import { pluralize } from '../utils';
import AnnotationListItem from './AnnotationListItem';
import Box from './Box';
import IconAdd from './IconAdd';
import styles from './AnnotationsList.module.css';

class AnnotationsList extends Component {
  constructor(props) {
    super(props);

    this.state = { scrollTop: 0 };
    this.listRef = React.createRef();

    this.onChangeLabel = this.onChangeLabel.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeIndex } = this.props;

    if (activeIndex != null && activeIndex !== prevProps.activeIndex) {
      const { current: list } = this.listRef;
      const item = list.children.item(activeIndex);
      if (item) {
        this.setState({ scrollTop: item.offsetTop });
      }
    }
  }

  onChangeLabel(index, label) {
    const { updateAnnotation, updateAnnotationLabel } = this.props;

    updateAnnotation(index, { label });
    updateAnnotationLabel(label);
  }

  renderList(annotations) {
    const { activeIndex, labels, onClick, onClickDelete } = this.props;

    return (
      <ul ref={this.listRef}>
        {annotations.map((annotation, index) => (
          <li key={annotation._id}>
            <AnnotationListItem
              annotation={annotation}
              index={index}
              isActive={index === activeIndex}
              labels={labels}
              onChangeLabel={this.onChangeLabel}
              onClick={onClick}
              onClickDelete={onClickDelete}
            />
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { addAnnotation, image } = this.props;
    const { annotations } = image;
    const { length: count } = annotations;
    const { scrollTop } = this.state;

    return (
      <Box
        addButton={(
          <button
            className={styles.addButton}
            onClick={addAnnotation}
            type="button"
          >
            <IconAdd />
          </button>
        )}
        title={`${count} ${pluralize(count, 'Annotation')}`}
        scrollTop={scrollTop}
        small
      >
        {this.renderList(annotations)}
      </Box>
    );
  }
}

const actions = {
  addAnnotation: imageActions.addImageAnnotation,
  onClick: annotationActions.setActiveAnnotation,
  onClickDelete: imageActions.deleteImageAnnotation,
  updateAnnotation: imageActions.updateImageAnnotation,
  updateAnnotationLabel: annotationActions.updateCurrentLabel,
};

export default connect(null, actions)(AnnotationsList);
