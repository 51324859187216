import produce from 'immer';
import {
  CHANGE_DASHBOARD_SEARCH,
  CHANGE_DASHBOARD_SORT,
} from '../actions/actionTypes';

export const initialState = {
  search: '',
  sort: '-createdAt',
};

export default produce(function dashboardReducer(draft, action) {
  const { payload, type } = action;

  switch(type) {
    case CHANGE_DASHBOARD_SEARCH:
      draft.search = payload;
      break;
    case CHANGE_DASHBOARD_SORT:
      draft.sort = payload;
      break;
    default:
      break;
  }
}, initialState);
