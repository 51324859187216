import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findImage } from '../actions/imageActions';
import { findActivePolylines as findPolylines } from '../actions/polylineActions';
import Loading from './Loading';

const activeImageChanged = (prev, current) => (
  (prev.activeImage == null && current.activeImage != null)
  || (
    prev.activeImage != null
    && current.activeImage != null
    && prev.activeImage._id !== current.activeImage._id
  )
);

const imagesDidLoad = (prev, current) => prev.imagesLoading && !current.imagesLoading;

const imageIdChanged = (prev, current) => prev.imageId !== current.imageId;

const refreshActiveImage = (prev, current) => (
  imagesDidLoad(prev, current)
  || imageIdChanged(prev, current)
);

class FocusImage extends Component {
  componentDidMount() {
    this.findActiveImage({});
    this.findActivePolylines({});
  }

  componentDidUpdate(prevProps) {
    this.findActiveImage(prevProps);
    this.findActivePolylines(prevProps);
  }

  findActiveImage(prevProps) {
    if (refreshActiveImage(prevProps, this.props)) {
      const { findActiveImage, imageId } = this.props;
      findActiveImage(imageId);
    }
  }

  findActivePolylines(prevProps) {
    const { polylinesCount, polylinesLoading } = this.props;
    if (!polylinesCount || polylinesLoading) return;

    if (activeImageChanged(prevProps, this.props)) {
      const { activeImage, findActivePolylines } = this.props;
      findActivePolylines(activeImage.worldReference);
    }
  }

  render() {
    const { activeImage, activeProject } = this.props;

    if (!activeImage || !activeProject) return <Loading />;

    return this.props.children;
  }
}

const mapStateToProps = ({ images, polylines, projects }) => ({
  activeImage: images.active,
  activeProject: projects.active,
  imagesLoading: images.loading,
  polylinesCount: polylines.globalCount,
  polylinesLoading: polylines.loading,
});

const actions = {
  findActiveImage: findImage,
  findActivePolylines: findPolylines,
};

export default connect(mapStateToProps, actions)(FocusImage);
