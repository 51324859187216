import produce from 'immer';
import {
  SET_ACTIVE_ANNOTATION,
  UNSET_ACTIVE_ANNOTATION,
  UPDATE_ANNOTATION_LABEL,
  UPDATE_ANNOTATION_SIZE,
} from '../actions/actionTypes';

export const initialState = {
  activeIndex: null,
  currentLabel: 0,
  currentHeight: 0.1,
  currentWidth: 0.1,
};

export default produce(function annotations(draft, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_ACTIVE_ANNOTATION: {
      draft.activeIndex = payload;
      break;
    }

    case UNSET_ACTIVE_ANNOTATION: {
      draft.activeIndex = null;
      break;
    }

    case UPDATE_ANNOTATION_LABEL: {
      draft.currentLabel = payload;
      break;
    }

    case UPDATE_ANNOTATION_SIZE: {
      draft.currentHeight = payload.height;
      draft.currentWidth = payload.width;
      break;
    }

    default:
      return;
  }
}, initialState)
