import React, { PureComponent } from 'react';
import IconTrash from './IconTrash';
import LabelSelect from './LabelSelect';
import styles from './AnnotationListItem.module.css';

export default class AnnotationListItem extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangeLabel = this.handleChangeLabel.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickTrash = this.handleClickTrash.bind(this);
  }

  handleChangeLabel(labelId) {
    const { index, onChangeLabel } = this.props;

    onChangeLabel(index, labelId);
  }

  handleClick() {
    const { index, onClick } = this.props;

    onClick(index);
  }

  handleClickTrash(event) {
    const { index, onClickDelete } = this.props;

    event.stopPropagation();
    onClickDelete(index);
  }

  render() {
    const { annotation, index, isActive, labels } = this.props;
    const { label } = annotation;

    return (
      <div
        className={isActive ? styles.rowActive : styles.row}
        onClick={this.handleClick}
      >
        <header className={styles.header}>
          <h5>Annotation {index + 1}</h5>
          <button
            type="button"
            onClick={this.handleClickTrash}
            className={styles.trash}
          >
            <IconTrash />
          </button>
        </header>
        <LabelSelect
          labels={labels}
          onChange={this.handleChangeLabel}
          value={label}
        />
      </div>
    );
  }
}
