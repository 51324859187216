import React from 'react';
import DashboardList from '../components/DashboardList';
import SessionInfo from '../components/SessionInfo';
import styles from './Dashboard.module.css';

export default function DashboardPage({
  items,
  onClick,
  onClickExport,
  onClickPublish,
  search,
  sort,
}) {
  return (
    <div className={styles.container}>
      <SessionInfo />
      <DashboardList
        items={items}
        onClick={onClick}
        onClickExport={onClickExport}
        onClickPublish={onClickPublish}
        search={search}
        sort={sort}
      />
    </div>
  );
}
