import produce from 'immer';
import {
  RESET_IMAGES,
  LOAD_IMAGES,
  LOAD_IMAGES_SUCCESS,
  LOAD_IMAGES_FAIL,
  FILTER_IMAGES_LIST,
  FIND_IMAGE_SUCCESS,
  FIND_IMAGE_FAIL,
  UPDATE_ACTIVE_IMAGE,
  UPDATE_GROUP_INFO,
  SAVE_IMAGE_SUCCESS,
  SAVE_IMAGE_FAIL,
} from '../actions/actionTypes';

export const initialState = {
  active: null,
  activeIndex: 0,
  error: null,
  filteredList: [],
  groups: null,
  hasChanges: false,
  list: [],
  projectId: null,
  loading: false,
};

export default produce(function imagesReducer(draft, action) {
  const { payload, type } = action;

  switch (type) {
    case RESET_IMAGES:
      return initialState;

    case LOAD_IMAGES: {
      draft.loading = true;
      break;
    }

    case LOAD_IMAGES_SUCCESS: {
      draft.error = null;
      draft.filteredList = payload.images;
      draft.groups = payload.groups;
      draft.list = payload.images;
      draft.loading = false;
      draft.projectId = payload.projectId;
      break;
    }

    case LOAD_IMAGES_FAIL: {
      draft.error = payload;
      draft.loading = false;
      break;
    }

    case FILTER_IMAGES_LIST: {
      draft.activeIndex = payload.activeIndex;
      draft.filteredList = payload.filteredList;
      draft.groups = payload.groups;
      break;
    }

    case FIND_IMAGE_SUCCESS: {
      draft.active = payload.active;
      draft.activeIndex = payload.activeIndex;
      draft.hasChanges = !payload.active.reviewed;
      draft.error = null;
      break;
    }

    case FIND_IMAGE_FAIL: {
      draft.active = null;
      draft.activeIndex = 0;
      draft.error = 'Image not found';
      break;
    }

    case UPDATE_ACTIVE_IMAGE: {
      draft.active = payload.active;
      draft.hasChanges = true;
      draft.list = payload.list;
      break;
    }

    case UPDATE_GROUP_INFO:
      draft.groups = payload;
      break;

    case SAVE_IMAGE_SUCCESS: {
      draft.active = payload;
      draft.error = null;
      break;
    }

    case SAVE_IMAGE_FAIL: {
      draft.error = payload;
      break;
    }

    default:
      return draft;
  }
}, initialState);
