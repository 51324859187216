import {
  SET_ACTIVE_ANNOTATION,
  UNSET_ACTIVE_ANNOTATION,
  UPDATE_ANNOTATION_LABEL,
  UPDATE_ANNOTATION_SIZE,
} from './actionTypes';

export function setActiveAnnotation(index) {
  if (index == null) {
    return { type: UNSET_ACTIVE_ANNOTATION };
  }

  return { type: SET_ACTIVE_ANNOTATION, payload: index };
}

export function updateCurrentLabel(label) {
  return { type: UPDATE_ANNOTATION_LABEL, payload: label };
}

export function updateCurrentSize({ height, width }) {
  return { type: UPDATE_ANNOTATION_SIZE, payload: { height, width } };
}
