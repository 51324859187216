import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { initialState, reducer } from './reducers';
import { logger } from './utils';

let composeMiddlewares = compose;

if (process.env.NODE_ENV !== 'production') {
  const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof extensionCompose === 'function') {
    composeMiddlewares = extensionCompose;
  }
}

const errorLogger = (store) => (next) => (action) => {
  const result = next(action);
  if (result && result.error === true) {
    logger.error(result.payload);
  }
  return result;
};

export default createStore(
  reducer,
  initialState,
  composeMiddlewares(applyMiddleware(errorLogger, thunk)),
);
