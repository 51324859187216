import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as projectActions from '../actions/projectActions';
import * as polylineActions from '../actions/polylineActions';

function FocusProject({ children, findProject, loadPolylines, projectId }) {
  useEffect(() => {
    findProject(projectId);
    loadPolylines(projectId);
  }, [projectId, findProject, loadPolylines]);

  return children;
}

const actions = {
  findProject: projectActions.findProject,
  loadPolylines: polylineActions.loadPolylines,
};

export default connect(null, actions)(FocusProject);
