import React, { PureComponent } from 'react';

export default class LabelSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target }) {
    const { onChange } = this.props;

    onChange(Number(target.value));
  }

  render() {
    const { labels, value } = this.props;

    return (
      <select disabled={1===labels.length} onClick={(event)=>event.stopPropagation()} onChange={this.handleChange} value={value}>
        {labels.map(item => (
          <option key={item.id} value={item.id}>
            {item.label}
          </option>
        ))}
      </select>
    );
  }
}
