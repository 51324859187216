import {
  RESET_LABELING_IMAGE,
  TOGGLE_IMAGE_LIST,
  UPDATE_SIBLING_INDEX,
} from './actionTypes';

export function reset() {
  return { type: RESET_LABELING_IMAGE };
}

export function toggleImageList() {
  return { type: TOGGLE_IMAGE_LIST };
}

export function updateSiblingIndex(siblingIndex) {
  return { type: UPDATE_SIBLING_INDEX, payload: siblingIndex };
}
