import { batch } from 'react-redux';
import { CHANGE_DASHBOARD_SEARCH, CHANGE_DASHBOARD_SORT } from './actionTypes';
import { resetFilters } from './filterActions';
import { resetImages } from './imageActions';
import { resetPolylines } from './polylineActions';

export function reset() {
  return (dispatch) => {
    batch(() => {
      dispatch(resetFilters());
      dispatch(resetImages());
      dispatch(resetPolylines());
    });
  };
}

export function search(query) {
  return { type: CHANGE_DASHBOARD_SEARCH, payload: query };
}

export function sort(order) {
  return { type: CHANGE_DASHBOARD_SORT, payload: order };
}
