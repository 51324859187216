import React from 'react';
import Simplebar from 'simplebar-react';

export default function Page({ children }) {
  return (
    <Simplebar style={{ height: '100%' }}>
      {children}
    </Simplebar>
  );
}
