import React, { Component } from 'react';
import IconQuestion from './IconQuestion';
import styles from './HelpPane.module.css';

const LABELING_HELP = [
  ['NEW ANNOTATION', 'space key'],
  ['ACTIVATE ANNOTATION', 'click it'],
  ['DELETE ACTIVE', 'delete key'],
];
const OBJECT_QC_HELP = [
  ['ACCEPT', 'y key'],
  ['REJECT', 'n key'],
  ['CHANGE VIEWS', 'left/right keys'],
  ['PREV/NEXT OBJECT', 'up/down keys'],
];
const POLYLINES_HELP = [
  ['ACTIVATE LINE', 'click a vertex'],
  ['MOVE VERTEX', 'click+drag to desired position'],
  ['NEW LINE', 'ctrl+click on image'],
  ['APPEND VERTEX', 'ctrl+click on endpoint then desired position'],
  ['INSERT VERTEX', 'ctrl+click on vertex then desired position'],
  ['DELETE VERTEX', 'shift+click on vertex'],
  ['SPLIT LINE', 'shift+ctrl+click on vertex'],
  ['JOIN LINES', 'ctrl+click on endpoints'],
  ['PREV/NEXT IMAGE', 'up/down keys'],
  ['SET PANO', 'alt+click on image']
];

function HelpContent({ isPolylines, projectType }) {
  let helpDocs = OBJECT_QC_HELP;

  if (projectType === 'Labeling') {
    helpDocs = LABELING_HELP;
  } else if (isPolylines) {
    helpDocs = POLYLINES_HELP;
  }

  return (
    <>
      <h2>Controls / Hotkeys</h2>
      {helpDocs.map(([name, msg], index) => (
        <p key={index}>
          <strong>{name}:</strong>
          {' '}
          {msg}
        </p>
      ))}
    </>
  );
}

export default class HelpPane extends Component {
  constructor(props) {
    super(props);

    this.paneRef = React.createRef();
    this.state = { isOpen: false };

    this.afterToggle = this.afterToggle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  afterToggle() {
    const { isOpen } = this.state;

    if (isOpen) {
      document.addEventListener('click', this.handleClickOutside);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    const paneEl = this.paneRef.current;

    if (!paneEl) return;

    if (!paneEl.contains(event.target)) {
      this.handleToggle();
    }
  }

  handleToggle(event) {
    if (event) {
      event.stopPropagation();
    }
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }), this.afterToggle);
  }

  render() {
    const { isPolylines, projectType } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <button className={styles.button} onClick={this.handleToggle}>
          <IconQuestion />
        </button>
        {isOpen && (
          <div ref={this.paneRef} className={styles.pane}>
            <HelpContent isPolylines={isPolylines} projectType={projectType} />
          </div>
        )}
      </>
    );
  }
}
