import React from 'react';
import { getProgress } from '../models/dashboard';
import styles from './DashboardListItem.module.css';

export default function DashboardProgress({
  errorMessage,
  isProcessing,
  isReviewComplete,
  isComplete,
  progress,
  project,
  projectType,
}) {
  function progressTitle() {
    if (projectType === 'Labeling') return 'Review Labels (optional)';
    if (errorMessage) return 'Processing Error';
    if (isProcessing) return 'Processing';
    if (isComplete) return 'Finished!'
    if (isReviewComplete) return 'Review Complete - Ready to Publish';
    return 'Needs Review';
  }

  function progressSubtitle() {
    if (!project) return 'Current Progress:';

    const { annotationCt, imageCt, reviewedCt } = project;
    return `${reviewedCt} / ${imageCt} images reviewed, ${annotationCt} objects`;
  }

  function renderProgressBar() {
    if (errorMessage) return <p className={styles.errorMsg}>{errorMessage}</p>;

    if (!progress && !project) return null;

    let cxSuffix = projectType;
    if (isProcessing) {
      cxSuffix = 'Processing';
    } else if (isComplete) {
      cxSuffix = 'Complete';
    } else if (isReviewComplete) {
      cxSuffix = 'ReviewComplete';
    }

    return (
      <>
        <small>
          {progressSubtitle()}
        </small>
        <div className={styles.fill}>
          <div
            className={styles[`fillContent${cxSuffix}`]}
            style={{ width: getProgress(progress, project) }}
          />
        </div>
      </>
    );
  }


  return (
    <>
      <strong>
        {progressTitle()}
      </strong>
      <div className={styles.progressRow}>
        {renderProgressBar()}
      </div>
    </>
  );
}
