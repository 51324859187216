export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_FAIL = 'LOAD_PROJECTS_FAIL';
export const PARSE_PROJECT_SUCCESS = 'PARSE_PROJECT_SUCCESS';
export const PARSE_PROJECT_FAIL = 'PARSE_PROJECT_FAIL';
export const FIND_PROJECT = 'FIND_PROJECT';
export const FIND_PROJECT_SUCCESS = 'FIND_PROJECT_SUCCESS';
export const FIND_PROJECT_FAIL = 'FIND_PROJECT_FAIL';
export const RELOAD_PROJECT = 'RELOAD_PROJECT';

export const LOAD_JOBS = 'LOAD_JOBS';
export const LOAD_JOBS_SUCCESS = 'LOAD_JOBS_SUCCESS';
export const LOAD_JOBS_FAIL = 'LOAD_JOBS_FAIL';
export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL';
export const ADVANCE_JOB = 'ADVANCE_JOB';
export const ADVANCE_JOB_SUCCESS = 'ADVANCE_JOB_SUCCESS';
export const ADVANCE_JOB_FAIL = 'ADVANCE_JOB_FAIL';
export const LOAD_DATA_SOURCES = 'LOAD_DATA_SOURCES';
export const LOAD_DATA_SOURCES_SUCCESS = 'LOAD_DATA_SOURCES_SUCCESS';
export const LOAD_DATA_SOURCES_FAIL = 'LOAD_DATA_SOURCES_FAIL';

export const CHANGE_DASHBOARD_SEARCH = 'CHANGE_DASHBOARD_SEARCH';
export const CHANGE_DASHBOARD_SORT = 'CHANGE_DASHBOARD_SORT';

export const CHANGE_CONFIDENCE_FILTER = 'CHANGE_CONFIDENCE_FILTER';
export const CHANGE_LABEL_FILTER = 'CHANGE_LABEL_FILTER'
export const RESET_FILTERS = 'RESET_FILTERS';

export const LOAD_IMAGES = 'LOAD_IMAGES';
export const LOAD_IMAGES_SUCCESS = 'LOAD_IMAGES_SUCCESS';
export const LOAD_IMAGES_FAIL = 'LOAD_IMAGES_FAIL';
export const FILTER_IMAGES_LIST = 'FILTER_IMAGES_LIST';
export const FIND_IMAGE_SUCCESS = 'FIND_IMAGE_SUCCESS';
export const FIND_IMAGE_FAIL = 'FIND_IMAGE_FAIL';
export const UPDATE_ACTIVE_IMAGE = 'UPDATE_ACTIVE_IMAGE';
export const UPDATE_GROUP_INFO = 'UPDATE_GROUP_INFO';
export const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS';
export const SAVE_IMAGE_FAIL = 'SAVE_IMAGE_FAIL';
export const RESET_IMAGES = 'RESET_IMAGES';

export const SET_ACTIVE_ANNOTATION = 'SET_ACTIVE_ANNOTATION';
export const UNSET_ACTIVE_ANNOTATION = 'UNSET_ACTIVE_ANNOTATION';
export const UPDATE_ANNOTATION_LABEL = 'UPDATE_ANNOTATION_LABEL';
export const UPDATE_ANNOTATION_SIZE = 'UPDATE_ANNOTATION_SIZE';

export const LOAD_POLYLINES = 'LOAD_POLYLINES';
export const LOAD_POLYLINES_SUCCESS = 'LOAD_POLYLINES_SUCCESS';
export const LOAD_POLYLINES_FAIL = 'LOAD_POLYLINES_FAIL';
export const FIND_ACTIVE_POLYLINES = 'FIND_ACTIVE_POLYLINES';
export const UPDATE_ACTIVE_POLYLINE = 'UPDATE_ACTIVE_POLYLINE';
export const UPDATE_POLYLINE_BATCH = 'UPDATE_POLYLINE_BATCH';
export const UPDATE_POLYLINE_WIDTH = 'UPDATE_POLYLINE_WIDTH';
export const CREATE_POLYLINE = 'CREATE_POLYLINE';
export const DELETE_POLYLINE = 'DELETE_POLYLINE';
export const CLEAR_POLYLINE_CHANGES = 'CLEAR_POLYLINE_CHANGES';
export const SET_CHANGED_POLYLINES = 'SET_CHANGED_POLYLINES';
export const RESET_POLYLINES = 'RESET_POLYLINES';
export const SET_ACTIVE_POLYLINE = 'SET_ACTIVE_POLYLINE';

export const RESET_LABELING_IMAGE = 'RESET_LABELING_IMAGE';
export const TOGGLE_IMAGE_LIST = 'TOGGLE_IMAGE_LIST';
export const UPDATE_SIBLING_INDEX = 'UPDATE_SIBLING_INDEX';
