import produce from 'immer';
import * as Actions from '../actions/actionTypes';

export const initialState = {
  active: null,
  error: null,
  fetched: false,
  list: [],
  loading: false,
  requested: false,
};

export default produce(function projectsReducer(draft, action) {
  switch (action.type) {
    case Actions.LOAD_PROJECTS: {
      draft.fetched = false;
      draft.loading = true;
      draft.requested = true;
      break;
    }

    case Actions.LOAD_PROJECTS_SUCCESS: {
      draft.error = null;
      draft.fetched = true;
      draft.list = action.payload;
      draft.loading = false;
      break;
    }

    case Actions.LOAD_PROJECTS_FAIL: {
      draft.error = action.payload;
      draft.fetched = false;
      draft.loading = false;
      break;
    }

    case Actions.PARSE_PROJECT_SUCCESS:
      draft.list.unshift(action.payload);
      break;

    case Actions.PARSE_PROJECT_FAIL:
      draft.error = action.payload;
      break;

    case Actions.FIND_PROJECT: {
      draft.active = null;
      draft.loading = true;
      break;
    }

    case Actions.FIND_PROJECT_SUCCESS: {
      draft.active = action.payload;
      draft.error = null;
      draft.loading = false;
      break;
    }

    case Actions.FIND_PROJECT_FAIL: {
      draft.error = action.payload;
      draft.loading = false;
      break;
    }

    case Actions.RELOAD_PROJECT:
      draft.list = action.payload;
      break;

    default:
      return;
  }
}, initialState);
