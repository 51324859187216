import React, { PureComponent } from 'react';
import Vector2 from '../utils/Vector2';
import { OPERATIONS } from '../models/polyline';

const style = {
  height: '100%',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  width: '100%',
};

const drawPoints = (context, points, getPoint) => {
  const isGetPointFunc = typeof getPoint === 'function';

  points.forEach((point, index) => {
    const { x, y } = isGetPointFunc ? getPoint(point) : point;

    if (index === 0) {
      context.moveTo(x, y);
    } else {
      context.lineTo(x, y);
    }
  });
};

const getPerpDir = (current, next) => {
  const dir = new Vector2(next.x - current.x, next.y - current.y);
  dir.normalize();

  return new Vector2(-dir.y, dir.x);
};

export default class PolylineSegments extends PureComponent {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.drawSegments = this.drawSegments.bind(this);
    this.initCanvas = this.initCanvas.bind(this);
  }

  componentDidMount() {
    this.initCanvas();
    this.drawSegments();
    window.addEventListener('resize', this.initCanvas);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationId);
    window.removeEventListener('resize', this.initCanvas);
  }

  initCanvas() {
    const context = this.getContext();

    this.resize();

    context.scale(1, 1);
    context.translate(0.5, 0.5);

    context.lineWidth = 2;
    context.strokeStyle = '#fff';
    context.fillStyle = '#fff';
  }

  getCanvas() {
    return this.canvasRef.current;
  }

  getContext(canvas) {
    return (canvas || this.getCanvas()).getContext('2d');
  }

  clearCanvas() {
    const canvas = this.getCanvas();
    const context = this.getContext(canvas);

    context.clearRect(0, -1, canvas.width, canvas.height + 1);
  }

  drawSegments() {
    this.animationId = window.requestAnimationFrame(this.drawSegments);

    this.clearCanvas();

    const { activeIndex, polylines } = this.props;
    const context = this.getContext();

    polylines.forEach((polyline, index) => {
      if (polyline.deleted || !polyline.visible) return;

      const active = activeIndex===index;
      this.drawShadows(context, polyline, active);
      this.drawLines(context, polyline, active);

      if (activeIndex === index) {
        this.drawProposedVertex(context, polyline);
      }
    });
    const { customCanvasEvents } = this.props;
    if (customCanvasEvents)
      customCanvasEvents.forEach(e=>e(context));
  }

  drawShadows(context, { aoiWidth, vertices }, active) {
    if (!aoiWidth) return;
    
    const { getVertexPosition } = this.props;
    const lastIndex = vertices.length - 1;
    let index = 0;

    const halfW = { x: 0.5 * aoiWidth.x, y: 0.5 * aoiWidth.y };

    for (; index < lastIndex; index += 1) {
      const current = vertices[index];
      const next = vertices[index + 1];
      const { x: perpX, y: perpY } = getPerpDir(current, next);
      const { x: curX, y: curY } = current;
      const { x: nextX, y: nextY } = next;

      const points = [
        getVertexPosition({
          x: curX + halfW.x * perpX,
          y: curY + halfW.y * perpY,
        }),
        getVertexPosition({
          x: nextX + halfW.x * perpX,
          y: nextY + halfW.y * perpY,
        }),
        getVertexPosition({
          x: nextX - halfW.x * perpX,
          y: nextY - halfW.y * perpY,
        }),
        getVertexPosition({
          x: curX - halfW.x * perpX,
          y: curY - halfW.y * perpY,
        }),
      ];

      context.strokeStyle = active ? '#fff' : '#666666';
      context.fillStyle = 'rgba(46, 188, 192, 0.2)';
      context.beginPath();
      context.setLineDash([]);
      drawPoints(context, points);
      context.closePath();
      if (active) context.stroke();
      context.fill();
    }
  }

  drawLines(context, { vertices }, isActive) {
    const { getVertexPosition } = this.props;

    context.beginPath();
    context.setLineDash([]);
    const oldStyle = context.strokeStyle;
    if (isActive) context.strokeStyle = '#fff';
    else          context.strokeStyle = '#666666';
    drawPoints(context, vertices, getVertexPosition);
    context.stroke();
    context.strokeStyle = oldStyle;
  }

  drawSplicePreview(context, activeVertexIndex, nextVertexIndex, vertices) {
    const { getVertexPosition } = this.props;
    context.strokeStyle='#f00';
    context.beginPath();

    const left = vertices[activeVertexIndex];
    if (left) {
      const leftPos = getVertexPosition(left);
      context.moveTo(leftPos.x, leftPos.y);
    }

    // 'UNSERT' operation
    let from = activeVertexIndex;
    let to = nextVertexIndex;
    if (from > to) {
      const tmp = from;
      from = to;
      to = tmp;
    }

    const start = vertices[from];
    if (start) {
      const pos = getVertexPosition(start);
      context.moveTo(pos.x, pos.y);
    }

    for (let ix = from+1; ix <= to; ++ix) {
      const vert = vertices[ix];
      const pos = getVertexPosition(vert);
      context.lineTo(pos.x, pos.y);
    }
    context.stroke();
    context.strokeStyle = '#fff';

    if (start) {
      context.beginPath();
      const pos = getVertexPosition(start);
      context.moveTo(pos.x, pos.y);

      const end = vertices[to];
      const ep = getVertexPosition(end);
      context.lineTo(ep.x, ep.y);
      context.stroke();
    }
  }

  drawProposedVertex(context, { vertices }) {
    const {
      activeVertexIndex,
      nextVertexIndex,
      getVertexPosition,
      operation,
      proposedVertex,
    } = this.props;

    context.beginPath();
    context.setLineDash([5]);

    if (operation === OPERATIONS.DELETE) {
      context.strokeStyle = '#f00';

      if (activeVertexIndex === 0 || activeVertexIndex === vertices.length - 1) {
        let nextIndex = activeVertexIndex + 1;
        if (nextIndex === vertices.length) {
          nextIndex = activeVertexIndex - 1;
        }

        const left = vertices[activeVertexIndex];
        if (left) {
          const { x, y } = getVertexPosition(left);
          context.moveTo(x, y);
        }

        const right = vertices[nextIndex];
        if (right) {
          const { x, y } = getVertexPosition(right);
          context.lineTo(x, y);
        }

        context.stroke();
        context.strokeStyle = '#fff';
        return;
      }

      const left = vertices[activeVertexIndex - 1];
      if (left) {
        const leftPos = getVertexPosition(left);
        context.moveTo(leftPos.x, leftPos.y);
      }

      const right = vertices[activeVertexIndex + 1];
      if (right) {
        const rightPos = getVertexPosition(right);
        context.lineTo(rightPos.x, rightPos.y);
      }

      context.stroke();
      context.strokeStyle = '#fff';
      return;
    }
    else if (operation === OPERATIONS.SPLIT) {
      let prevIndex = activeVertexIndex - 1;
      if (prevIndex < 0) return;

      context.strokeStyle = '#f00';

      const left = vertices[prevIndex];
      if (left) {
        const { x, y } = getVertexPosition(left);
        context.moveTo(x, y);
      }

      const right = vertices[activeVertexIndex];
      if (right) {
        const { x, y } = getVertexPosition(right);
        context.lineTo(x, y);
      }

      context.stroke();
      context.strokeStyle = '#fff';
      return;
    }

    if (proposedVertex == null) return;

    const neighbor = activeVertexIndex + 1;
    // Special rendering for splice action.
    if (nextVertexIndex !== null && nextVertexIndex!==neighbor && nextVertexIndex!==activeVertexIndex) {
      this.drawSplicePreview(context, activeVertexIndex, nextVertexIndex, vertices);
      return;
    }

    const left = vertices[activeVertexIndex];
    if (left) {
      const leftPos = getVertexPosition(left);
      context.moveTo(leftPos.x, leftPos.y);
    }

    const proposedPos = getVertexPosition(proposedVertex);
    context.lineTo(proposedPos.x, proposedPos.y);
    
    if (operation === OPERATIONS.INSERT) {
      const right = vertices[neighbor];
      if (right) {
        const rightPos = getVertexPosition(right);

        context.lineTo(rightPos.x, rightPos.y);
      }
    }

    context.stroke();
    context.strokeStyle = '#fff';
  }

  resize() {
    const canvas = this.getCanvas();
    const { clientWidth, clientHeight } = canvas.offsetParent;

    canvas.width = clientWidth;
    canvas.height = clientHeight;
  }

  render() {
    return <canvas ref={this.canvasRef} style={style} />;
  }
}
