const DEFAULT_KEY = '_id';

export default class Store {
  constructor(modelType, keyProperty = DEFAULT_KEY) {
    Object.defineProperties(this, {
      keyProperty: { value: keyProperty },
      modelType: { value: modelType },
      store: { value: new Map() },
    });
  }

  clear() {
    this.store.clear();
  }

  get(key) {
    return this.store.get(key);
  }

  add(item) {
    if (Array.isArray(item)) {
      this.addBulk(item);
      return;
    }

    this.store.set(item[this.keyProperty], item);
  }

  addBulk(items) {
    items.forEach(item => this.add(item));
  }

  remove(item) {
    if (Array.isArray(item)) {
      this.removeBulk(item);
      return;
    }

    this.store.delete(item[this.keyProperty] || item);
  }

  removeBulk(items) {
    items.forEach(item => this.remove(item));
  }

  list() {
    return Array.from(this.store.values());
  }

  count() {
    return this.store.size;
  }
}
