import React, { Component } from 'react';
import * as Image from '../models/image';
import ImageQuestion from './ImageQuestion';

export default class ImageQuestions extends Component {
  constructor(props) {
    super(props);

    this.handleChangeCount = this.handleChangeCount.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  handleChangeCount(count, index) {
    this.updateQuestion(index, { object_ct: count });
  }

  handleChangeLabel(label, index) {
    this.updateQuestion(index, { label });
  }

  updateQuestion(index, changes) {
    const { image, onChange } = this.props;
    const yesNoLabels = Image.updateQuestion(image, index, changes);

    onChange({ yesNoLabels });
  }

  renderQuestion(question, index) {
    const { labels, onChangeSurvey } = this.props;

    return (
      <ImageQuestion
        key={question._id}
        index={index}
        labels={labels}
        onChangeCount={this.handleChangeCount}
        onChangeLabel={this.handleChangeLabel}
        onToggle={onChangeSurvey}
        question={question}
      />
    );
  }

  render() {
    const { image } = this.props;
    const { yesNoLabels = [] } = image;

    if (!yesNoLabels.length) return null;

    return (
      <div style={{ marginTop: 10 }}>
        <label>Does this image contain a:</label>
        {yesNoLabels.map(this.renderQuestion)}
      </div>
    );
  }
}
