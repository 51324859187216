import React from 'react';
import spinner from './spinner.svg';
import styles from './Loading.module.css';

export default function Loading() {
  return (
    <div className={styles.spinnerContainer}>
      <img src={spinner} alt="Loading..." />
    </div>
  );
}
