import memoizeOne from 'memoize-one';
import produce from 'immer';
import { prepareAnnotationsForSave } from './annotation';
import { initialState } from '../reducers/filtersReducer';

export function addAnnotation(image, annotation) {
  return produce(image, (draft) => {
    draft.annotations.push(annotation);
  });
}

function imageHasConfidence(image, confidence) {
  return image.confidence <= confidence;
}

function imageHasLabel(image, label) {
  const { annotations, yesNoLabels } = image;

  return (
    annotations.some(annotation => annotation.label === label)
    || yesNoLabels.some(question => question.label === label)
  );
}

export const filterImages = memoizeOne(function filterImages(images, filters) {
  const { confidence, label } = filters;
  const isDefaultConfidence = confidence === initialState.confidence;
  const isDefaultLabel = label === initialState.label;

  if (isDefaultConfidence && isDefaultLabel) {
    return images;
  }

  return images.reduce((filtered, image) => {
    if (
      (isDefaultConfidence && imageHasLabel(image, label))
      || (isDefaultLabel && imageHasConfidence(image, confidence))
      || (imageHasConfidence(image, confidence) && imageHasLabel(image, label))
    ) filtered.push(image);

    return filtered;
  }, []);
});

export function getActiveIndex(images, active) {
  if (!images.length || !active) return -1;

  const activeId = active._id;

  return images.findIndex(image => image._id === activeId);
}

export function getURL(image) {
  return `/projects/${image.projId}/images/${image._id}`;
}

const DEFAULT_GROUP_ID = '00000000-0000-0000-0000-000000000000';
function addImageToGroups(image, groups) {
  const { groupID, yesNoLabels, contextInfo } = image;

  if (!groupID || groupID === DEFAULT_GROUP_ID) return;

  const groupInfo = groups.get(groupID) || {
    accepted: 0,
    firstIndex: image.reviewIdx || 0,
    groupIndex: null,
    images: [],
    total: 0,
    groupType: "Unknown",
    groupExpectedCount: 1
  };

  if (groupInfo.groupType === "Unknown" && contextInfo && contextInfo.groupType)
  {
    groupInfo.groupType = contextInfo.groupType;
  }

  if (contextInfo && contextInfo.expectedCount != null)
  {
    groupInfo.groupExpectedCount = contextInfo.expectedCount;
  }

  if (yesNoLabels && yesNoLabels.length && yesNoLabels[0].answer) {
    groupInfo.accepted += 1;
  }

  groupInfo.firstIndex = Math.min(groupInfo.firstIndex, image.reviewIdx);
  groupInfo.total += 1;

  groupInfo.images.push(image);

  groups.set(groupID, groupInfo);
}

export function groupImages(images) {
  let groups = new Map();

  images.forEach(image => addImageToGroups(image, groups));

  if (groups.size) {
    groups.forEach((info, index) => {
      info.images.sort((left, right) => {
        if (left.confidence != null && right.confidence != null) {
          return right.confidence - left.confidence;
        }
        return sortByReviewIndex(left, right);
      });
      info.groupIndex = index;
    });
  } else {
    groups = null;
  }

  return groups;
}

export function imageHasPolylines({ worldReference }) {
  return worldReference != null && (
    worldReference.bottom > 0
    && worldReference.left > 0
    && worldReference.right > 0
    && worldReference.top > 0
  );
}

export function prepareImageForSave(image) {
  const annotations = prepareAnnotationsForSave(image.annotations);

  return produce(image, (draft) => {
    draft.annotations = annotations;
    draft.reviewed = true;
  });
}

export function removeAnnotation(image, annotationIndex) {
  return produce(image, (draft) => {
    draft.annotations.splice(annotationIndex, 1);
  });
}

function sortByReviewIndex(left, right) {
  if (left.reviewIdx != null && right.reviewIdx != null) {
    return left.reviewIdx - right.reviewIdx;
  }

  if (left.createdAt != null && right.createdAt != null) {
    const leftDate = new Date(left.createdAt).getTime();
    const rightDate = new Date(right.createdAt).getTime();

    return leftDate - rightDate;
  }

  return 0;
}

export function sortImages(images, groups) {
  if (!groups) {
    return [...images].sort(sortByReviewIndex);
  }

  const groupedSorted = [...groups.values()]
    .sort((left, right) => left.firstIndex - right.firstIndex)
    .map(({ images }) => images)
    .flat();
  const ungroupedSorted = images.filter(({ groupID }) => !groups.has(groupID))
    .sort(sortByReviewIndex);

  return [...groupedSorted, ...ungroupedSorted];
}

export function shouldToggle(image, index, yesOrNo) {
  return (
    yesOrNo != null
    && image.yesNoLabels
    && (image.yesNoLabels.length > index)
    && image.yesNoLabels[index].answer !== yesOrNo
  );
}

export function toggleSurvey(image, index) {
  return produce(image, (draft) => {
    draft.yesNoLabels[index].answer = !draft.yesNoLabels[index].answer;
    draft.useForTraining = true;
  });
}

export function updateAnnotation(image, annotationIndex, changes = {}) {
  return produce(image, (draft) => {
    const annotation = draft.annotations[annotationIndex];

    draft.annotations[annotationIndex] = { ...annotation, ...changes };
  });
}

function answerChanged(updated, original) {
  return (
    updated.yesNoLabels
    && original.yesNoLabels
    && updated.yesNoLabels.length
    && original.yesNoLabels.length
    && updated.yesNoLabels[0].answer !== original.yesNoLabels[0].answer
  );
}
export function updateGroupInfo(groups, image, originalImage) {
  const { groupID } = image;
  let groupInfo = groups.get(groupID);

  if (!groupInfo) return groups;

  // clone frozen objects
  groups = new Map(groups);
  groupInfo = { ...groupInfo };

  if (answerChanged(image, originalImage)) {
    if (image.yesNoLabels[0].answer) {
      groupInfo.accepted += 1;
    } else {
      groupInfo.accepted -= 1;
    }
  }

  groupInfo.images = groupInfo.images.map((img) => {
    if (img._id === image._id) return image;
    return img;
  });

  groups.set(groupID, groupInfo);

  return groups;
}

export function updateQuestion(image, index, changes = {}) {
  return produce(image.yesNoLabels, (draft) => {
    const question = draft[index];

    Object.entries(changes).forEach(([key, val]) => {
      question[key] = val;
    });
  });
}

export function update(image, changes = {}, isQcProject = false) {
  return produce(image, (draft) => {
    let hasTrainingKey = false;

    Object.entries(changes).forEach(([key, value]) => {
      if (key === 'useForTraining') {
        hasTrainingKey = true;
      }

      draft[key] = value;
    });

    if (!hasTrainingKey && isQcProject) {
      draft.useForTraining = true;
    }
  });
}
