import React, { Fragment, PureComponent } from 'react';
import LabelSelect from './LabelSelect';
import YesNoSwitch from './YesNoSwitch';

export default class ImageQuestion extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangeCount = this.handleChangeCount.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
  }

  handleChangeCount({ target }) {
    const { index, onChangeCount } = this.props;

    onChangeCount(Number(target.value), index);
  }

  handleChangeLabel(value) {
    const { index, onChangeLabel } = this.props;

    onChangeLabel(value, index);
  }

  render() {
    const { labels, onToggle, question, index } = this.props;
    const { answer, label, labelMap } = question;

    // If the question has a custom label map, use it.  Otherwise use the passed one.
    const options = (labelMap && labelMap.length) ? labelMap : labels;

    return (
      <Fragment>
        <div style={{ marginBottom: 10 }}>
          <LabelSelect
            labels={options}
            onChange={this.handleChangeLabel}
            value={label}
          />
          <YesNoSwitch
            id={question._id}
            checked={answer}
            onChange={()=> onToggle(index)}
          />
        </div>
      </Fragment>
    );
  }
}
