import produce from 'immer';
import {
  CHANGE_CONFIDENCE_FILTER,
  CHANGE_LABEL_FILTER,
  RESET_FILTERS,
} from '../actions/actionTypes';

export const initialState = {
  confidence: 1,
  label: '',
};

export default produce(function filtersReducer(draft, action) {
  const { payload, type } = action;

  switch (type) {
    case RESET_FILTERS:
      return initialState;

    case CHANGE_CONFIDENCE_FILTER:
      draft.confidence = payload;
      break;

    case CHANGE_LABEL_FILTER:
      draft.label = payload;
      break;

    default:
      break;
  }
}, initialState);
