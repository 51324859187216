import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Dashboard from './routes/Dashboard';
import Loading from './components/Loading';
import Login from './pages/Login';
import Logout from './routes/Logout';
import Navbar from './components/Navbar';
import NotFound from './pages/NotFound';
import PrivateRoute from './routes/PrivateRoute';
import Projects from './routes/Projects';

import { init as initAuth } from './actions/authActions';
import { navigation } from './utils';

import store from './store';

export default function App() {
  const [checkAuth, setCheckAuth] = useState(true);

  useEffect(() => {
    if (checkAuth) {
      initAuth()
        .then(() => {
          navigation.redirectAuthenticatedUserToDashboard();
          setCheckAuth(false);
        })
        .catch(() => {
          navigation.logout();
          setCheckAuth(false);
        });
    }
  }, [checkAuth, setCheckAuth]);

  if (checkAuth) return <Loading />;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Navbar />
        <section role="main">
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/projects" component={Projects} />
            <Route component={NotFound} />
          </Switch>
        </section>
      </BrowserRouter>
    </Provider>
  );
}
