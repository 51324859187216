import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import styles from './CameraOverlay.module.css';

const ENABLE_RESIZING = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false,
};
const SIZE = 40;
const OFFSET = 40 / 2;
const SIZE_PROP = { height: SIZE, width: SIZE };

const addOffset = ({ x, y }) => ({ x: x + OFFSET, y: y + OFFSET });

const subtractOffset = ({ x, y }) => ({ x: x - OFFSET, y: y - OFFSET });

export default class WorldPointOverlay extends Component {
  constructor(props) {
    super(props);

    this.onDrag = this.onDrag.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  onDragStart(event, dragEvent) {
    this.props.onDragStart(event,dragEvent);
  }

  onDrag(event, dragEvent) {
    const { onDrag } = this.props;
    onDrag(addOffset(dragEvent), false);
  }

  onDragStop(event, dragEvent) {
    const { onDragEnd } = this.props;
    onDragEnd(addOffset(dragEvent), true);
  }

  render() {
    const { position } = this.props;

    return (
      <Rnd
        bounds={null}
        className={styles.cam_overlay}
        disableDragging={false}
        enableResizing={ENABLE_RESIZING}
        onClick={()=>{}}
        onContextMenu={()=>{}}
        onDragStart={this.onDragStart}
        onDrag={this.onDrag}
        onDragStop={this.onDragStop}
        onMouseEnter={()=>{}}
        onMouseLeave={()=>{}}
        position={subtractOffset(position)}
        size={SIZE_PROP}
      />
    );
  }
}
