import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import styles from './PolylineVertex.module.css';

const SIZE = 10;
const OFFSET = SIZE / 2;
const ENDPOINT_SIZE = 10 * 1.5;
const ENDPOINT_OFFSET = ENDPOINT_SIZE / 2;
const SIZE_PROP = {
  height: SIZE,
  width: SIZE,
};
const ENDPOINT_SIZE_PROP = {
  height: ENDPOINT_SIZE,
  width: ENDPOINT_SIZE,
};

function addOffset(point, isEndpoint = false) {
  if (isEndpoint) {
    return {
      x: point.x + ENDPOINT_OFFSET,
      y: point.y + ENDPOINT_OFFSET,
    };
  }

  return {
    x: point.x + OFFSET,
    y: point.y + OFFSET,
  };
}

function subtractOffset(point, isEndpoint = false) {
  if (isEndpoint) {
    return {
      x: point.x - ENDPOINT_OFFSET,
      y: point.y - ENDPOINT_OFFSET,
    };
  }

  return {
    x: point.x - OFFSET,
    y: point.y - OFFSET,
  };
}

export default class PolylineVertex extends Component {
  constructor(props) {
    super(props);

    this.onDrag = this.onDrag.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
  }

  onDrag(event, data) {
    if (!data.deltaX && !data.deltaY) return; // no change

    const { isEndpoint, onDrag } = this.props;
    const vertex = addOffset(data, isEndpoint);

    onDrag(vertex);
  }

  onDragStop(event, data) {
    const { isEndpoint, onDrag } = this.props;
    const vertex = addOffset(data, isEndpoint);

    onDrag(vertex, true);
  }

  getClassName() {
    const { isActive, isEndpoint } = this.props;

    if (isEndpoint) {
      return styles.overlay_endpoint;
    }

    if (isActive) {
      return styles.overlay_active;
    }

    return styles.overlay;
  }

  render() {
    const {
      isDisabled,
      isEndpoint,
      onClick,
      onDragStart,
      onMouseEnter,
      onMouseLeave,
      position,
    } = this.props;

    return (
      <Rnd
        className={this.getClassName()}
        disableDragging={isDisabled}
        enableResizing={false}
        onClick={isDisabled ? null : onClick}
        onContextMenu={isDisabled ? null : onClick}
        onDragStart={onDragStart}
        onDrag={this.onDrag}
        onDragStop={this.onDragStop}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        position={subtractOffset(position, isEndpoint)}
        size={isEndpoint ? ENDPOINT_SIZE_PROP : SIZE_PROP}
      />
    );
  }
}
