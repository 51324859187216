import React, { useEffect, useRef, useState } from 'react';
import DashboardButton from './DashboardButton';
import DashboardIcon from './DashboardIcon';
import DashboardProgress from './DashboardProgress';
import { datetime } from '../utils';
import styles from './DashboardListItem.module.css';
import { auth } from '../utils';
import CreateJob from './CreateJob';
import { dataSourceStore } from '../data';

export default function ListItem({
  data,
  onClick,
  onClickExport,
  onClickPublish,
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const {
    createdAt,
    errorMessage,
    isProcessing,
    isReviewComplete,
    isComplete,
    progress,
    project,
    projectName,
    projectType,
    driveId,
  } = data;
  const isQcProject = projectType === 'QC';

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  const handleClickExport = () => {
    onClickExport(project);
    toggleMenu();
  };

  const handleClickPublish = () => {
    onClickPublish(data.id);
    if (isMenuOpen) toggleMenu();
  };

  useEffect(() => {
    if (isMenuOpen) {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };

      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [isMenuOpen, setMenuOpen, menuRef]);

  let driveName =  `Drive ${driveId}`;
  let realDId = driveId;
  if (driveId === '(Ingestion Jobs)') {
    driveName = 'Pending Ingestion Jobs';
    realDId = null;
  }
  else if (driveId === '(Labeling Projects)') {
    driveName = 'Labeling Projects';
    realDId = null;
  }

  // If not projectName or type, this is a placeholder for a Drive parent.
  if (!projectName && !projectType) {
    return (
      <div className={styles.grouprow}>
        <div className={styles.nameColumn}>
          <p>{ driveName }</p>
        </div>
        {auth.isAdmin() && <CreateJob driveId={realDId} datasources={ dataSourceStore } />}
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <div className={styles.iconColumn}>
        <DashboardIcon
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          isQcProject={isQcProject}
          isReviewComplete={isReviewComplete}
          isComplete={isComplete}
        />
      </div>
      <div className={styles.nameColumn}>
        <p>{projectName}</p>
        <small>
          {datetime.formatDate(createdAt)}
          &nbsp;&ndash;&nbsp;
          {datetime.formatTime(createdAt)}
        </small>
      </div>
      <div className={styles.progressColumn}>
        <DashboardProgress
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          isReviewComplete={isReviewComplete}
          isComplete={isComplete}
          progress={progress}
          project={project}
          projectType={projectType}
        />
      </div>
      <div className={styles.btnColumn}>
        <DashboardButton
          handleClickExport={handleClickExport}
          handleClickPublish={handleClickPublish}
          handleClickView={onClick}
          isMenuOpen={isMenuOpen}
          isProcessing={isProcessing}
          isQcProject={isQcProject}
          isReviewComplete={isReviewComplete}
          isComplete={isComplete}
          menuRef={menuRef}
          project={project}
          toggleMenu={toggleMenu}
        />
      </div>
    </div>
  );
}
