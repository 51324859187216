import React, { PureComponent } from 'react';
import Simplebar from 'simplebar-react';
import Loading from './Loading';
import styles from './Box.module.css';

export default class Box extends PureComponent {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { scrollTop } = this.props;

    if (scrollTop !== prevProps.scrollTop) {
      this.scrollRef.current.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: scrollTop,
      });
    }
  }

  getHeight() {
    const { small, title } = this.props;

    if (title != null) {
      if (small) {
        return 'calc(100% - 64px)';
      }

      return 'calc(100% - 104px)';
    }

    return '100%';
  }

  render() {
    const {
      addButton,
      children,
      loading,
      projectcount,
      small,
      title,
    } = this.props;
    const className = small ? styles.small : styles.box;
    const height = this.getHeight();

    return (
      <div className={className}>
        {title != null && (
          <header>
            <h4>{title}</h4>
            {addButton}
            {projectcount > 0 && (
              <div className={styles.count}>
                {projectcount}
              </div>
            )}
          </header>
        )}
        <Simplebar
          scrollableNodeProps={{ ref: this.scrollRef }}
          style={{ height }}
        >
          {!loading && children}
          {loading && <Loading />}
        </Simplebar>
      </div>
    );
  }
}
