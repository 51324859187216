import {
  LOAD_IMAGES,
  LOAD_IMAGES_SUCCESS,
  LOAD_IMAGES_FAIL,
  FILTER_IMAGES_LIST,
  FIND_IMAGE_SUCCESS,
  FIND_IMAGE_FAIL,
  SAVE_IMAGE_SUCCESS,
  SAVE_IMAGE_FAIL,
  UPDATE_ACTIVE_IMAGE,
  UPDATE_GROUP_INFO,
  SET_ACTIVE_ANNOTATION,
  UNSET_ACTIVE_ANNOTATION,
  RESET_IMAGES,
} from './actionTypes';
import { api } from '../utils';
import { imageStore, projectStore } from '../data';
import { createAnnotation } from '../models/annotation';
import { isQcProject } from '../models/project';
import * as ImageModel from '../models/image';

function updateActiveImage(dispatch, active, filters, groups) {
  dispatch({
    type: UPDATE_ACTIVE_IMAGE,
    payload: {
      active,
      list: imageStore.list(),
    },
  });
  dispatch(filterImagesList(filters));
}

export function resetImages() {
  imageStore.clear();

  return { type: RESET_IMAGES };
}

export function loadImages(projectId) {
  return (dispatch, getState) => {
    const { images } = getState();

    if (images.loading || images.projectId === projectId) return;

    dispatch({ type: LOAD_IMAGES });

    return api.getProjectImages(projectId)
      .then((images) => {
        const groups = ImageModel.groupImages(images);

        imageStore.addBulk(images);

        dispatch({
          type: LOAD_IMAGES_SUCCESS,
          payload: {
            groups,
            projectId,
            images: ImageModel.sortImages(imageStore.list(), groups),
          },
        });
      })
      .catch((err) => {
        dispatch({ type: LOAD_IMAGES_FAIL, payload: err, error: true });
      });
  };
}

export function filterImagesList(filters) {
  return (dispatch, getState) => {
    const { images } = getState();
    const filtered = ImageModel.filterImages(images.list, filters);
    const groups = ImageModel.groupImages(filtered);
    const sortedAndFiltered = ImageModel.sortImages(filtered, groups);
    let activeIndex = ImageModel.getActiveIndex(sortedAndFiltered, images.active);

    if (activeIndex < 0) {
      activeIndex = 0;
    }

    dispatch({
      type: FILTER_IMAGES_LIST,
      payload: {
        activeIndex,
        groups,
        filteredList: sortedAndFiltered,
      },
    });
  };
}

export function findImage(id) {
  return (dispatch, getState) => {
    const { images } = getState();

    if (images.active && images.active._id === id) return;

    const image = imageStore.get(id);

    if (image) {
      const activeIndex = ImageModel.getActiveIndex(images.filteredList, image);

      dispatch({
        type: FIND_IMAGE_SUCCESS,
        payload: { active: image, activeIndex },
      });
    } else {
      dispatch({ type: FIND_IMAGE_FAIL });
    }
  };
}

export function updateImage(changes = {}) {
  return (dispatch, getState) => {
    const { filters, images } = getState();
    const { active, groups } = images;
    const image = imageStore.get(active._id);
    const project = projectStore.get(image.projId);
    const updated = ImageModel.update(image, changes, isQcProject(project));

    if (groups) {
      const updatedGroups = ImageModel.updateGroupInfo(groups, updated, image);
      dispatch({ type: UPDATE_GROUP_INFO, payload: updatedGroups });
    }

    imageStore.add(updated);

    updateActiveImage(dispatch, updated, filters, groups);
  };
}

function getCenter(position, { height, left, scale, top, width }) {
  if (scale > 1) {
    switch (position) {
      case 'left':
        return ((window.innerWidth / 2) - left) / (width * scale);
      case 'top':
        return ((window.innerHeight / 2) - top) / (height * scale);
      default:
        return 0.5;
    }
  }
  return 0.5;
}

export function addImageAnnotation() {
  return (dispatch, getState) => {
    const { annotations, filters, images, labeling } = getState();
    const { active, groups } = images;
    const image = imageStore.get(active._id);
    const annotationLeft = getCenter('left', labeling);
    const annotationTop = getCenter('top', labeling);

    const annotation = createAnnotation({
      label: annotations.currentLabel,
      height: annotations.currentHeight,
      width: annotations.currentWidth,
      left: annotationLeft,
      top: annotationTop,
    });
    const updated = ImageModel.addAnnotation(image, annotation);

    imageStore.add(updated);

    updateActiveImage(dispatch, updated, filters, groups);
    dispatch({
      type: SET_ACTIVE_ANNOTATION,
      payload: updated.annotations.length - 1,
    });
  };
}

export function updateImageAnnotation(index, changes = {}) {
  return (dispatch, getState) => {
    const { filters, images } = getState();
    const { active, groups } = images;
    const image = imageStore.get(active._id);
    const updated = ImageModel.updateAnnotation(image, index, changes);

    imageStore.add(updated);

    updateActiveImage(dispatch, updated, filters, groups);
    dispatch({ type: SET_ACTIVE_ANNOTATION, payload: index });
  };
}

export function deleteImageAnnotation(index) {
  return (dispatch, getState) => {
    if (index == null) return;

    const { annotations, filters, images } = getState();
    const { active, groups } = images;
    const image = imageStore.get(active._id);
    const updated = ImageModel.removeAnnotation(image, index);

    imageStore.add(updated);

    updateActiveImage(dispatch, updated, filters, groups);

    if (annotations.activeIndex === index) {
      dispatch({ type: UNSET_ACTIVE_ANNOTATION });
    } else if (annotations.activeIndex > index) {
      dispatch({
        type: SET_ACTIVE_ANNOTATION,
        payload: annotations.activeIndex - 1,
      });
    }
  };
}

export function saveImage(image) {
  return (dispatch, getState) => {
    return api.updateProjectImage(ImageModel.prepareImageForSave(image))
      .then((updated) => {
        const { filters, images } = getState();
        const { active } = images;

        imageStore.add(updated);

        if (active && active._id === updated._id) {
          dispatch({ type: SAVE_IMAGE_SUCCESS, payload: updated });
        }

        dispatch(filterImagesList(filters));
      })
      .catch((err) => {
        dispatch({ type: SAVE_IMAGE_FAIL, payload: err, error: true });
      });
  };
}
