import React from 'react';

function getColor({ accepted, groupExpectedCount }) {
  if (accepted === groupExpectedCount) return '#22dd22';
  if (accepted < groupExpectedCount) return '#dd2222';
  return '#dddd22';
}

function getStyle(groupInfo) {
  return {
    color: getColor(groupInfo),
    fontSize: '1.25em',
  };
}

export default function GroupInfo({ groupInfo, image }) {
  if (!groupInfo) return null;

  const imageId = image._id;
  const index = 1 + groupInfo.images.findIndex(img => img._id === imageId);

  return (
    <div style={{ marginTop: '4em' }}>
      <header style={ { borderBottom: '1px solid #bac8c8', padding: '20px 0px 15px', height: '64px' } }><h4>Groundtruth Info</h4></header>
      <br/>
      <p style={getStyle(groupInfo)}>Type: {groupInfo.groupType}</p>
      <p style={getStyle(groupInfo)}>{groupInfo.accepted} / { groupInfo.groupExpectedCount } accepted</p>
      <p style={ { fontSize: '0.75em' } }>Candidate {index} / {groupInfo.total}</p>
      <p style={ { fontSize: '0.75em' } }>{ groupInfo.groupIndex }</p>
    </div>
  );
}
