import * as Actions from './actionTypes';
import { api, logger } from '../utils';
import { parseProject } from '../models/project';
import { projectStore } from '../data';
import { updateCurrentLabel } from './annotationActions';

export function addLocalProject(jsonFile) {
  return dispatch => parseProject(jsonFile)
    .then((project) => {
      projectStore.add(project);
      dispatch({ type: Actions.PARSE_PROJECT_SUCCESS, payload: project });
    })
    .catch((err) => {
      dispatch({
        type: Actions.PARSE_PROJECT_FAIL,
        payload: err,
        error: true,
      });
    });
}

export function exportProject(project) {
  return () => {
    const filename = `${project.projectName}.json`;
    const projectId = project._id;

    Promise.all([
      api.getProjectImages(projectId),
      api.getProjectPolylines(projectId)
    ])
      .then(([images, polylines]) => {
        const data = JSON.stringify({
          ...project,
          ImageList: images,
          WorldPolylines: polylines,
        }, null, 2);
        const file = new Blob([data], { type: 'application/json' });
        const link = document.createElement('a');

        link.download = filename;
        link.href = URL.createObjectURL(file);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        logger.error(err);
        alert('Uh oh, something went wrong. Please try again.');
      });
  };
}

export function loadProjects() {
  return (dispatch, getState) => {
    const { projects } = getState();

    if (projects.loading || projects.fetched) return;

    dispatch({ type: Actions.LOAD_PROJECTS });

    return api.getLabelingProjects()
      .then((projects) => {
        projectStore.addBulk(projects);
        dispatch({ type: Actions.LOAD_PROJECTS_SUCCESS, payload: projects });
      })
      .catch((err) => {
        dispatch({ type: Actions.LOAD_PROJECTS_FAIL, payload: err, error: true });
      });
  };
}

export function findProject(id) {
  return (dispatch, getState) => {
    const { projects } = getState();

    if (projects.active && projects.active._id === id) return;

    const project = projectStore.get(id);

    if (project) {
      dispatch({ type: Actions.FIND_PROJECT_SUCCESS, payload: project });
      return;
    }

    dispatch({ type: Actions.FIND_PROJECT });

    return api.getLabelingProject(id)
      .then((project) => {
        projectStore.add(project);

        dispatch(updateCurrentLabel(project.LabelMap[0].id));
        dispatch({ type: Actions.FIND_PROJECT_SUCCESS, payload: project });
      })
      .catch((err) => {
        dispatch({
          type: Actions.FIND_PROJECT_FAIL,
          payload: err,
          error: true,
        });
      });
  };
}

export function reloadProject(id) {
  return dispatch => api.getLabelingProject(id)
    .then((project) => {
      projectStore.add(project);
      dispatch({ type: Actions.RELOAD_PROJECT, payload: projectStore.list() });
    })
    .catch((err) => {
      logger.error(err);
    });
}
