import produce from 'immer';
import {
  RESET_LABELING_IMAGE,
  TOGGLE_IMAGE_LIST,
  UPDATE_SIBLING_INDEX,
} from '../actions/actionTypes';

export const initialState = {
  isListOpen: false,
  siblingIndex: null,
};

export default produce(function labeling(draft, action) {
  const { payload, type } = action;

  switch(type) {
    case RESET_LABELING_IMAGE:
      return initialState;

    case TOGGLE_IMAGE_LIST: {
      draft.isListOpen = !draft.isListOpen;
      break;
    }

    case UPDATE_SIBLING_INDEX: {
      draft.siblingIndex = payload;
      break;
    }

    default:
      break;
  }
}, initialState);
