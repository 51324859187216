import React from 'react';
import styles from './Icon.module.css';

export default function IconAdd({ title }) {
  return (
    <svg className={styles.icon} viewBox="0 0 20 20">
      <title>{title || 'add'}</title>
      <path d="M9.375 4h0.625v13.125h-0.625v-13.125zM3.125 10.25h13.125v0.625h-13.125v-0.625z" />
    </svg>
  );
}
