import React from 'react';
import { connect } from 'react-redux';
import { sort as sortAction } from '../actions/dashboardActions';
import styles from './DashboardSort.module.css';

function DashboardSort({ sort, value }) {
  return (
    <div className={styles.wrapper}>
      Sort by&nbsp;
      <select
        className={styles.select}
        onChange={(event) => sort(event.currentTarget.value)}
        value={value}
      >
        <option value="-createdAt">Newest First</option>
        <option value="createdAt">Oldest First</option>
        <option value="projectName">Project Name: A&ndash;Z</option>
        <option value="-projectName">Project Name: Z&ndash;A</option>
      </select>
    </div>
  );
}

const actions = {
  sort: sortAction,
};

export default connect(null, actions)(DashboardSort);
