import Client from './http';
import { API, getApiUrl } from './api';

const defaultClient = new Client(getApiUrl(window));
const api = new API(defaultClient);

export { default as Panzoom } from './Panzoom';
export { api };
export { default as auth } from './auth';
export { default as clamp } from './clamp';
export { default as confirm } from './confirm';
export { default as createId } from './createId';
export { default as datetime } from './datetime';
export { default as logger } from './logger';
export { default as navigation } from './navigation';
export { default as pluralize } from './pluralize';
