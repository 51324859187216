import React, { Component } from 'react';

import Box from './Box';
import GroupInfo from './GroupInfo';
import ImageQuestions from './ImageQuestions';
import YesNoSwitch from './YesNoSwitch';

function getConfidenceColor(confidence) {
  if (confidence < 0.75) return '#dd2222';

  if (confidence < 0.9) return '#dddd22';

  return '#22dd22';
}

function getConfidenceStyle(confidence) {
  return {
    color: getConfidenceColor(confidence),
    fontSize: '2em',
  };
}

export default class ImageProperties extends Component {
  constructor(props) {
    super(props);

    this.handleChangeTraining = this.handleChangeTraining.bind(this);
  }

  handleChangeTraining() {
    const { image, updateImage } = this.props;

    updateImage({ useForTraining: !image.useForTraining });
  }

  render() {
    const { groupInfo, image, labels, toggleSurvey, updateImage } = this.props;
    const { confidence = 0 } = image;

    return (
      <Box title="Image Properties" small>
        <div style={{ padding: 20 }}>
          <p style={getConfidenceStyle(confidence)}>
            {(confidence * 100).toFixed(2)}%
          </p>
          <YesNoSwitch
            id="use_for_training"
            label="Use this image for training:"
            checked={image.useForTraining}
            onChange={this.handleChangeTraining}
          />
          <ImageQuestions
            image={image}
            labels={labels}
            onChange={updateImage}
            onChangeSurvey={toggleSurvey}
          />
          <GroupInfo groupInfo={groupInfo} image={image} />
        </div>
      </Box>
    );
  }
}
