import React from 'react';
import FocusImage from '../components/FocusImage';
import LabelingPage from '../pages/Labeling';

export default function Labeling({ history, match }) {
  const { params } = match;
  const navigateTo = (path) => history.push(path);

  return (
    <FocusImage imageId={params.imageId}>
      <LabelingPage navigateTo={navigateTo} />
    </FocusImage>
  );
}
