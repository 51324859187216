import React, { Component } from 'react';
import { getURL } from '../models/image';
import ImageList from './ImageList';
import SiblingNav from './SiblingNav';
import styles from './Labeling.module.css';

export default class LabelingNavigation extends Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();

    this.handleKey = this.handleKey.bind(this);
    this.jumpBackward = this.navigateToImage.bind(this, -4);
    this.jumpForward = this.navigateToImage.bind(this, 4);
    this.navigateToNextImage = this.navigateToImage.bind(this, 1);
    this.navigateToNextSibling = this.navigateToSibling.bind(this, 1);
    this.navigateToPrevImage = this.navigateToImage.bind(this, -1);
    this.navigateToPrevSibling = this.navigateToSibling.bind(this, -1);
  }

  componentDidMount() {
    this.focus();
  }

  componentDidUpdate() {
    this.focus();
  }

  handleKey({ key }) {
    switch (key) {
      case 'ArrowUp':
      case 'w':
        return this.navigateToPrevImage();
      case 'ArrowDown':
      case 's':
        return this.navigateToNextImage();
      case 'ArrowRight':
      case 'd':
        return this.navigateToNextSibling();
      case 'ArrowLeft':
      case 'a':
        return this.navigateToPrevSibling();
      case ' ':
      case '0':
        return this.props.addAnnotation();
      case 'q':
        return this.navigateToFirstAnnotation();
      case 'e':
        return this.navigateToLastAnnotation();
      case 'y':
        return this.props.toggleSurvey(0, true);
      case 'n':
        return this.props.toggleSurvey(0, false);
      case 'x':
        return this.jumpForward();
      case 'z':
        return this.jumpBackward();
      case 'Backspace':
      case 'Delete':
        return this.removeAnnotation();
      case 'Esc':
      case 'Escape':
        return this.returnToDashboard();
      default:
        break;
    }
  }

  focus() {
    this.elementRef.current.focus();
  }

  navigateToFirstAnnotation() {
    const { images, navigateTo } = this.props;
    const { length } = images;
    let index = 0;
    let target = images[index];

    for (; index < length; index += 1) {
      const image = images[index];
      if (image.annotations && image.annotations.length) {
        target = image;
        break;
      }
    }

    navigateTo(getURL(target));
  }

  navigateToImage(direction) {
    const { activeIndex, images, navigateTo } = this.props;
    const { length } = images;
    let nextIndex = activeIndex + direction;

    if (nextIndex < 0 || nextIndex > length) {
      nextIndex += length;
    } else if (nextIndex === length) {
      nextIndex = 0;
    }

    navigateTo(getURL(images[nextIndex]));
  }

  navigateToLastAnnotation() {
    const { images, navigateTo } = this.props;
    const { length } = images;
    let index = length -1;
    let target = images[index];

    for (; index > -1; index -= 1) {
      const image = images[index];
      if (image.annotations && image.annotations.length) {
        target = image;
        break;
      }
    }

    navigateTo(getURL(target));
  }

  navigateToSibling(direction) {
    const { activeImage, siblingIndex, updateSiblingIndex } = this.props;
    const { length } = activeImage.siblingImages;
    let index;

    if (siblingIndex == null) {
      index = direction === -1 ? length - 1 : 0;
    } else {
      index = siblingIndex + direction;
    }

    if (index === length || index < 0) {
      updateSiblingIndex(null);
    } else {
      updateSiblingIndex(index);
    }
  }

  removeAnnotation() {
    const { activeAnnotationIndex, removeAnnotation } = this.props;

    removeAnnotation(activeAnnotationIndex);
  }

  returnToDashboard() {
    this.props.navigateTo('/');
  }

  render() {
    const {
      activeImage,
      activeIndex,
      children,
      filters,
      images,
      isListOpen,
      labels,
      projectType,
      toggleList,
    } = this.props;
    const siblingImages = (activeImage && activeImage.siblingImages) || [];

    return (
      <div
        ref={this.elementRef}
        className={styles.wrapper}
        onKeyUp={this.handleKey}
        tabIndex={0}
      >
        {children}
        <ImageList
          activeIndex={activeIndex}
          filters={filters}
          images={images}
          isOpen={isListOpen}
          labels={labels}
          projectType={projectType}
          toggleList={toggleList}
        />
        <SiblingNav
          disabled={!siblingImages || !siblingImages.length}
          onClickNext={this.navigateToNextSibling}
          onClickPrev={this.navigateToPrevSibling}
        />
      </div>
    );
  }
}
