export function getItem(key) {
  return window.localStorage.getItem(key);
}

export function hasItem(key) {
  return !!getItem(key);
}

export function removeItem(key) {
  window.localStorage.removeItem(key);
}

export function setItem(key, value) {
  window.localStorage.setItem(key, value);
}

export default {
  getItem,
  hasItem,
  removeItem,
  setItem,
};
