import React from 'react';
import styles from './YesNoSwitch.module.css';

export default function YesNoSwitch({ id, label, onChange, checked = false }) {
  return (
    <div>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <div className={checked ? styles.sliderChecked : styles.slider}>
        <span className={styles.no}>No</span>
        <span className={styles.yes}>Yes</span>
        <input
          id={id}
          className={styles.input}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
