import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as imageActions from '../actions/imageActions';

function LoadImages({ children, loadImages, projectId }) {
  useEffect(() => {
    loadImages(projectId);
  }, [projectId, loadImages]);

  return children;
}

export default connect(null, { loadImages: imageActions.loadImages })(LoadImages);
