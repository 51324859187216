import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { auth } from '../utils';

export default function PrivateRoute({ component: Component, ...props }) {
  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (auth.isAuthenticated()) return <Component {...routeProps} />;

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
}
