import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  addPolyline,
  addVertexToPolyline,
  insertPolylineVertex,
} from '../actions/polylineActions';
import CameraOverlay from './CameraOverlay';
import ImageOverlays from './ImageOverlays';
import styles from './AnnotatedImage.module.css';
import { OPERATIONS } from '../models/polyline';
import { Panzoom, api, auth } from '../utils';

function getPosition(event) {
  const { clientX, clientY, currentTarget } = event;
  const { height, left, top, width } = currentTarget.getBoundingClientRect();

  return {
    x: (clientX - left) / width,
    y: (clientY - top) / height,
  };
}

function isAddOperation(operation) {
  return (
    operation === OPERATIONS.APPEND
    || operation === OPERATIONS.PREPEND
    || operation === OPERATIONS.INSERT
  );
}

export class AnnotatedImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeVertexIndex: null,
      errorMessage: null,
      imageHeight: 0,
      imageWidth: 0,
      imageX: 0,
      imageY: 0,
      isLoaded: false,
      panoCameraDir: null,
      panoCameraFov: null,
      panoCameraPos: null,
      polylineOperation: null,
      proposedVertex: null,
      proposedCameraPosition: null,
    };

    this.imageRef = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleLoadError = this.handleLoadError.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handlePanoChange = this.handlePanoChange.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 250);
    this.handleTransform = this.handleTransform.bind(this);
    this.setPolylineOperation = this.setPolylineOperation.bind(this);
    this.onChangeStart = this.toggleInteracting.bind(this, true);
    this.onChangeEnd = this.toggleInteracting.bind(this, false);
    this.renderPanoCameraFOV = this.renderPanoCameraFOV.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate({ isImageListOpen: wasImageListOpen }) {
    const { isImageListOpen } = this.props;

    if (wasImageListOpen !== isImageListOpen) {
      this.toggleInteracting(isImageListOpen);
    }
  }

  componentWillUnmount() {
    if (this.panzoom) {
      this.panzoom.dispose();
    }
    this.handleResize.cancel();
    window.removeEventListener('resize', this.handleResize);
  }

  handleCameraQuickMove(event) {
    this.setState({ proposedCameraPosition: getPosition(event) });
  }

  handleClick(event) {
    if (!event.ctrlKey) {
      if (event.altKey) {
        this.handleCameraQuickMove(event);
      }
      return;
    }

    event.preventDefault();

    const { polylineOperation } = this.state;
    const position = getPosition(event);
    let update;

    switch (polylineOperation) {
      case OPERATIONS.APPEND: {
        const { addVertex } = this.props;
        update = (state) => ({ activeVertexIndex: state.activeVertexIndex + 1 });
        addVertex(position, polylineOperation);
        break;
      }
      case OPERATIONS.INSERT: {
        const { activeVertexIndex } = this.state;
        const { insertVertex } = this.props;
        insertVertex(position, activeVertexIndex);
        break;
      }
      case OPERATIONS.PREPEND: {
        const { addVertex } = this.props;
        update = { activeVertexIndex: 0 };
        addVertex(position, polylineOperation);
        break;
      }
      default: {
        const { createPolyline } = this.props;
        update = {
          activeVertexIndex: 0,
          polylineOperation: OPERATIONS.APPEND,
        };
        createPolyline(position);
      }
    }

    if (update) {
      this.setState(update);
    }
  }

  handleLoad() {
    this.setImageSize();
    this.setState({ errorMessage: null, isLoaded: true }, () => {
      this.panzoom = new Panzoom(this.imageRef.current, this.handleTransform);
      this.panzoom.center();
    });
  }

  handleLoadError(event) {
    // check status code
    api.getLabelingImage(event.target.src)
      .catch((err) => {
        const errorMessage = err.status === 404
          ? 'Image Not Found'
          : 'Error Loading Image';

        this.setState({ errorMessage, isLoaded: true });
      });
  }

  handleMouseMove(event) {
    const { polylineOperation } = this.state;
    if (polylineOperation == null) return;

    if (isAddOperation(polylineOperation)) {
      if (event.ctrlKey) {
        this.setState({ proposedVertex: getPosition(event) });
      } else {
        this.setState({ polylineOperation: null, proposedVertex: null });
      }
    }
  }

  handlePanoChange(panoCameraDir, panoCameraFov, panoCameraPos) {
    this.setState({ panoCameraDir, panoCameraFov, panoCameraPos });
  }

  handleResize() {
    this.setImageSize();
    this.panzoom.center();
  }

  handleTransform() {
    const { scale, x, y } = this.panzoom.getTransform();

    this.setState({
      imageHeight: this.imageHeight * scale,
      imageWidth: this.imageWidth * scale,
      imageX: x,
      imageY: y,
    });
  }

  setImageSize() {
    const image = this.imageRef.current;
    this.imageHeight = image.clientHeight;
    this.imageWidth = image.clientWidth;
  }

  setPolylineOperation({ activeVertexIndex, polylineOperation }) {
    this.setState({ activeVertexIndex, polylineOperation });
  }

  toggleInteracting(isUserInteracting) {
    if (!this.panzoom) return;

    if (isUserInteracting) {
      this.panzoom.pause();
    } else {
      this.panzoom.resume();
    }
  }

  uncomputeVertex({ x, y }) {
    const { imageHeight, imageWidth, imageX, imageY } = this.state;

    return {
      x: imageX + (x * imageWidth),
      y: imageY + (y * imageHeight),
    };
  }

  renderPanoCameraFOV(context) {
    // Draw a camera FOV cone at specified position and direction.
    const { panoCameraDir, panoCameraFov, panoCameraPos } = this.state;
    if (!panoCameraDir || !panoCameraFov || !panoCameraPos) return;

    // Do math in converted coordinates - it doesn't work in 0-1. See below.
    const origin = this.uncomputeVertex(panoCameraPos);
    // Normalize direction in 2D since it comes in in 3D. Flip y for image convention.
    const dir2D = {
      x: panoCameraDir.x,
      y: -panoCameraDir.y,
    };
    const dirLength = Math.sqrt(dir2D.x * dir2D.x + dir2D.y * dir2D.y);
    dir2D.x /= dirLength;
    dir2D.y /= dirLength;
    // This is in pixels now, just use 100 pixels to start.
    // TODO: scale the distance when the image zooms
    const dist = 100;
    // Covert angle to radians for math.
    const fovRad = Math.PI * panoCameraFov / 180;
    const leftAngle = Math.atan2(dir2D.y, dir2D.x) - fovRad / 2;
    const rightAngle = Math.atan2(dir2D.y, dir2D.x) + fovRad / 2;
    const vertices = [
      {
        x: origin.x + dist * Math.cos(leftAngle),
        y: origin.y + dist * Math.sin(leftAngle),
      },
      {
        x: origin.x + dist * Math.cos(rightAngle),
        y: origin.y + dist * Math.sin(rightAngle),
      },
      origin,
    ];

    context.beginPath();
    context.setLineDash([]);

    context.moveTo(origin.x, origin.y);
    vertices.forEach(({ x, y }) => context.lineTo(x, y));

    context.stroke();
    context.fill();
  }

  render() {
    const {
      activeAnnotationIndex,
      activePolylineIndex,
      driveInfo,
      hasPolylines,
      image,
      labels,
      polylines,
      projectType,
      siblingIndex,
    } = this.props;
    const {
      activeVertexIndex,
      errorMessage,
      imageHeight,
      imageWidth,
      imageX,
      imageY,
      isLoaded,
      polylineOperation,
      proposedVertex,
      proposedCameraPosition,
    } = this.state;
    const token = auth.getToken();
    const src = api.getLabelingImageURL(token, image._id, siblingIndex);

    return (
      <>
        <img
          ref={this.imageRef}
          alt=""
          className={styles.image}
          onClick={hasPolylines ? this.handleClick : null}
          onContextMenu={hasPolylines ? this.handleClick : null}
          onError={this.handleLoadError}
          onLoad={this.handleLoad}
          onMouseMove={hasPolylines ? this.handleMouseMove : null}
          tabIndex={0}
          src={src}
          crossOrigin={"anonymous"}
        />
        <ImageOverlays
          activeAnnotationIndex={activeAnnotationIndex}
          activePolylineIndex={activePolylineIndex}
          activeVertexIndex={activeVertexIndex}
          customCanvasEvents={[this.renderPanoCameraFOV]}
          errorMessage={errorMessage}
          hasPolylines={hasPolylines}
          labels={labels}
          image={image}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          imageX={imageX}
          imageY={imageY}
          setPolylineOperation={this.setPolylineOperation}
          isLoaded={isLoaded}
          onChangeEnd={this.onChangeEnd}
          onChangeStart={this.onChangeStart}
          polylineOperation={polylineOperation}
          polylines={polylines}
          projectType={projectType}
          proposedVertex={proposedVertex}
          siblingIndex={siblingIndex}
        />
        {driveInfo != null && hasPolylines && (
          <CameraOverlay
            driveInfo={driveInfo}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            imageX={imageX}
            imageY={imageY}
            onCameraChange={this.handlePanoChange}
            onChangeEnd={this.onChangeEnd}
            onChangeStart={this.onChangeStart}
            operation={polylineOperation}
            worldRef={image.worldReference}
            proposedCameraPosition={ proposedCameraPosition }
          />
        )}
      </>
    );
  }
}

const actions = {
  createPolyline: addPolyline,
  addVertex: addVertexToPolyline,
  insertVertex: insertPolylineVertex,
};

export default connect(null, actions)(AnnotatedImage);
