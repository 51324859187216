import {
  CHANGE_CONFIDENCE_FILTER,
  CHANGE_LABEL_FILTER,
  RESET_FILTERS,
} from './actionTypes';
import { filterImagesList } from './imageActions';

export function changeConfidenceFilter(confidence) {
  return (dispatch, getState) => {
    const { filters } = getState();
    const confidenceNumber = Number.parseFloat(confidence / 100);

    dispatch({ type: CHANGE_CONFIDENCE_FILTER, payload: confidenceNumber });
    dispatch(filterImagesList({
      label: filters.label,
      confidence: confidenceNumber,
    }));
  };
}

export function changeLabelFilter(label) {
  return (dispatch, getState) => {
    const { filters } = getState();
    let value = label;

    if (label !== '') {
      value = Number.parseInt(label, 10);
    }

    dispatch({ type: CHANGE_LABEL_FILTER, payload: value });
    dispatch(filterImagesList({
      label: value,
      confidence: filters.confidence,
    }));
  };
}

export function resetFilters() {
  return { type: RESET_FILTERS };
}
