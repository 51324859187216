import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createJob as jobAction } from '../actions/jobActions';
import IconAdd from './IconAdd';
import JobForm from './ProcessingJobForm';
import styles from './CreateJob.module.css';

function CreateJob({ createJob, driveId, datasources }) {
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => setOpen(false);

  const handleSubmit = (jobParams) => {
    createJob(jobParams);
    closeModal();
  };

  if (datasources && datasources.store) datasources = Array.from(datasources.store, ([k,v])=>v);

  return (
    <>
      <button
        className={styles.btn}
        onClick={() => setOpen(true)}
      >
        <IconAdd title="Create Job" />
      </button>
      <JobForm
        closeModal={closeModal}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        driveId={driveId}
        datasources={datasources}
      />
    </>
  );
}

const actions = {
  createJob: jobAction,
};

export default connect(null, actions)(CreateJob);
