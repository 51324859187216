import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as projectActions from '../actions/projectActions';
import { loadDataSources as loadDataSourceAction } from '../actions/jobActions';

function LoadProjects({ children, loadProjects, loadDataSources }) {
  useEffect(() => {
    loadProjects();
    loadDataSources();
  }, [loadProjects, loadDataSources]);

  return children;
}

export default connect(null, { loadProjects: projectActions.loadProjects, loadDataSources: loadDataSourceAction })(LoadProjects);
