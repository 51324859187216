import produce from 'immer';
import {
  CLEAR_POLYLINE_CHANGES,
  CREATE_POLYLINE,
  DELETE_POLYLINE,
  FIND_ACTIVE_POLYLINES,
  LOAD_POLYLINES,
  LOAD_POLYLINES_FAIL,
  LOAD_POLYLINES_SUCCESS,
  RESET_POLYLINES,
  SET_CHANGED_POLYLINES,
  SET_ACTIVE_POLYLINE,
  UPDATE_ACTIVE_POLYLINE,
  UPDATE_POLYLINE_BATCH,
  UPDATE_POLYLINE_WIDTH,
} from '../actions/actionTypes';

export const initialState = {
  active: [],
  activeIndex: null,
  changedPolylines: {},
  error: null,
  globalCount: 0,
  hasChanges: false,
  lastPolyLabel: 0,
  lastPolyWidth: 2.5,
  loading: false,
  projectId: null,
};

export default produce(function polylines(draft, action) {
  const { payload } = action;

  switch (action.type) {
    case LOAD_POLYLINES: {
      draft.loading = true;
      draft.error = null;
      break;
    }

    case LOAD_POLYLINES_SUCCESS: {
      draft.loading = false;
      draft.globalCount = payload.count;
      draft.projectId = payload.projectId;
      break;
    }

    case LOAD_POLYLINES_FAIL: {
      draft.loading = false;
      draft.globalCount = 0;
      draft.error = payload;
      break;
    }

    case FIND_ACTIVE_POLYLINES: {
      draft.active = payload;
      break;
    }

    case UPDATE_ACTIVE_POLYLINE: {
      if (payload == null) break;

      draft.active.splice(draft.activeIndex, 1, payload);
      draft.changedPolylines[payload._id] = payload;
      draft.hasChanges = true;
      draft.lastPolyLabel = payload.label;
      draft.lastPolyWidth = payload.aoiWidthWorld;
      break;
    }

    case UPDATE_POLYLINE_BATCH: {
      const { indices, polylines } = payload;
      for (let idx = 0; idx !== indices.length; ++idx) {
        draft.active.splice(indices[idx], 1, polylines[idx]);
        draft.changedPolylines[polylines[idx]._id] = polylines[idx];
      }
      draft.hasChanges = true;
      break;
    }

    case UPDATE_POLYLINE_WIDTH: {
      draft.lastPolyWidth = payload;
      break;
    }

    case CREATE_POLYLINE: {
      if (payload == null) break;

      draft.activeIndex = draft.active.push(payload) - 1;
      draft.changedPolylines[payload._id] = payload;
      draft.hasChanges = true;
      break;
    }

    case DELETE_POLYLINE: {
      const { index, polyline } = payload;
      draft.active.splice(index, 1);
      draft.changedPolylines[polyline._id] = polyline;
      draft.hasChanges = true;
      break;
    }

    case SET_CHANGED_POLYLINES: {
      payload.forEach((poly) => {
        draft.changedPolylines[poly._id] = poly;
      });
      draft.hasChanges = true;
      break;
    }

    case CLEAR_POLYLINE_CHANGES: {
      draft.changedPolylines = {};
      draft.hasChanges = false;
      break;
    }

    case SET_ACTIVE_POLYLINE: {
      draft.activeIndex = payload;
      break;
    }

    case RESET_POLYLINES:
      return initialState;

    default:
      break;
  }
}, initialState);
